import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Container } from "reactstrap";

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import logoPic from "../assets/img/brand/logo.png";
import logoMini from "../assets/img/brand/logomini.png";
import routes from "routes.js";

import DetailOrderUPS from "../views/detailOrder/DetailOrderUPS.js";
import DetailOrderCable from "../views/detailOrder/DetailOrderCable.js";
import DetailOrder3M from "../views/detailOrder/DetailOrder3M.js";
import DetailOrderSchneider from "../views/detailOrder/DetailOrderSchneider.js";
import DetailOrderAccessories from "../views/detailOrder/DetailOrderAccessories.js";

import DetailNewOrder from "../views/DetailNewOrder.js";

import ManageProductCable from "../views/ManageProductCable.js";
import ManageProductUps from "../views/ManageProductUps.js";
import ManageProduct3M from "../views/ManageProduct3M.js";
import ManageProductSchneider from "../views/ManageProductSchneider.js";
import ManageProductAccessories from "../views/ManageProductAccessories.js";

import NotFound from "../views/NotFound.js";

class Admin extends React.Component {
	componentDidUpdate(e) {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		this.refs.mainContent.scrollTop = 0;
	}
	getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.layout === "/") {
				return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
			} else {
				return null;
			}
		});
	};
	getBrandText = (path) => {
		for (let i = 0; i < routes.length; i++) {
			if (this.props.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
				return routes[i].name;
			}
		}
		let pathname = path.substring(1);
		let parts = pathname.split(/\//);
		if (parts.length > 1) {
			let result = parts[0];
			return result;
		}
		let result = parts[1];
		return result;
	};
	render() {
		return (
			<>
				<Sidebar
					{...this.props}
					routes={routes}
					logo={{
						innerLink: "/home",
						imgSrc: logoPic,
						imgMini: logoMini,
						imgAlt: "PT. Sentratek Metalindo",
					}}
				/>
				<div className="main-content" ref="mainContent">
					<AdminNavbar {...this.props} brandText={this.getBrandText(this.props.location.pathname)} />
					<Switch>
						{this.getRoutes(routes)}

						<Route path="/detail-order/:idOrder/ups/:idProduct" component={DetailOrderUPS} />
						<Route path="/detail-order/:idOrder/3m/:idProduct" component={DetailOrder3M} />
						<Route path="/detail-order/:idOrder/cable/:idProduct" component={DetailOrderCable} />
						<Route path="/detail-order/:idOrder/schneider/:idProduct" component={DetailOrderSchneider} />
						<Route path="/detail-order/:idOrder/accessories/:idProduct" component={DetailOrderAccessories} />

						<Route path="/detail-order/:idOrder" exact component={DetailNewOrder} />

						<Route path="/cable/edit/:idProduct" render={(props) => <ManageProductCable {...props} status={"Edit"} />} />
						<Route path="/cable/add" render={(props) => <ManageProductCable {...props} status={"Add"} />} />

						<Route path="/ups/edit/:idProduct" render={(props) => <ManageProductUps {...props} status={"Edit"} />} />
						<Route path="/ups/add" render={(props) => <ManageProductUps {...props} status={"Add"} />} />

						<Route path="/3m/edit/:idProduct" render={(props) => <ManageProduct3M {...props} status={"Edit"} />} />
						<Route path="/3m/add" render={(props) => <ManageProduct3M {...props} status={"Add"} />} />

						<Route path="/schneider/edit/:idProduct" render={(props) => <ManageProductSchneider {...props} status={"Edit"} />} />
						<Route path="/schneider/add" render={(props) => <ManageProductSchneider {...props} status={"Add"} />} />

						<Route path="/accessories/edit/:idProduct" render={(props) => <ManageProductAccessories {...props} status={"Edit"} />} />
						<Route path="/accessories/add" render={(props) => <ManageProductAccessories {...props} status={"Add"} />} />

						<Redirect from="*" to="/home" />
						<Route path="*" component={NotFound} />
					</Switch>
					<Container fluid>
						<AdminFooter />
					</Container>
				</div>
			</>
		);
	}
}

export default Admin;
