import React from "react";
import { Card, CardHeader, CardBody, Container, Row, Button } from "reactstrap";
import Header from "components/Headers/Header.js";
import DataTableAccessories from "../components/DataTables/TableProductsAccessories.js";
import { Link } from "react-router-dom";
import axiosInstance from "../services/AxiosService";
import LoadingPic from "../assets/img/theme/ripple-loading.gif";
import { Helmet } from "react-helmet";

class Product extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			listAccessories: null,
			taskDone: false,
		};
	}

	fetchDataAccessories() {
		const url = "/accessories";
		axiosInstance
			.get(url)
			.then((response) => {
				this.setState({ listAccessories: response.data });
			})
			.catch((error) => {
				alert(error.message);
			});
	}

	componentDidMount() {
		this.fetchDataAccessories();
	}

	handleStatusDeleteAccessories() {
		this.setState({ taskDone: true, listAccessories: null });
		this.fetchDataAccessories();
	}

	render() {
		console.log(this.state);
		return (
			<>
				<Helmet>
					<title>Produk PT Sentratek Metalindo</title>
					<meta name="description" content="Admin PT Sentratek Metalindo " />
				</Helmet>
				<Header />
				<Container className="mt--7" fluid>
					<Row className="mt-4">
						<div className=" col">
							<Card className=" shadow">
								<CardHeader className=" bg-transparent">
									<h3 className=" mb-0 d-inline">Daftar Produk Accessories</h3>
									<Button color="primary" size="md" className="fa-pull-right" tag={Link} to="/accessories/add">
										Tambah Accessories Baru
									</Button>
								</CardHeader>
								<CardBody>
									{this.state.listAccessories === null ? (
										<div className="text-center">
											<img src={LoadingPic} alt="..." />
										</div>
									) : (
										<DataTableAccessories listProduct={this.state.listAccessories} handleStatusDeleteAccessories={this.handleStatusDeleteAccessories.bind(this)} />
									)}
								</CardBody>
							</Card>
						</div>
					</Row>
				</Container>
			</>
		);
	}
}

export default Product;
