import React from "react";
import { Button, Container, Row, Col } from "reactstrap";
import moment from "moment";
import axiosInstance from "../../services/AxiosService";
import pic1 from "../../assets/img/brand/sentra2.jpg";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

class UserHeader extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loadingConfirm: false,
			alert: null,
		};
	}

	changeDateFormat = (date) => {
		const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
		let dateNew = moment(date).local().format("DD/MM/YYYY");
		var splitted = dateNew.split("/");
		// let time = moment(date).local().format("HH:mm");
		return `${splitted[0]} ${monthNames[splitted[1] - 1]} ${splitted[2]}`;
	};

	handleConfirm(e, id, status) {
		e.preventDefault();
		this.setState({ loadingConfirm: true });
		const dataToPost = {
			idOrder: id,
			confirm: !status,
			admin: this.props.user,
		};
		const url = `/ordering/confirm`;

		axiosInstance
			.post(url, dataToPost)
			.then((response) => {
				if (response.data === "Success") {
					this.setState({ loadingConfirm: false });
					window.location.reload(false);
				}
			})
			.catch((error) => {
				alert(error.message);
			});
	}

	handleFinishOrder(e, id, status) {
		e.preventDefault();
		const alertDone = () => (
			<SweetAlert warning showCancel cancelBtnText="Batal" confirmBtnText="Ya, selesaikan" confirmBtnBsStyle="danger" title="Anda yakin?" onConfirm={() => this.doneOrder(id, status)} onCancel={() => this.setState({ alert: null })} focusCancelBtn>
				Dengan menyelesaikan pesanan ini, maka tidak dapat diubah kembali
			</SweetAlert>
		);

		this.setState({
			alert: alertDone(),
		});
	}

	doneOrder(id, status) {
		const dataToPost = {
			idOrder: id,
			confirm: 2,
			admin: this.props.user,
		};
		const url = `/ordering/confirm`;

		axiosInstance
			.post(url, dataToPost)
			.then((response) => {
				if (response.data === "Success") {
					this.setState({ loadingConfirm: false });
					window.location.reload(false);
				}
			})
			.catch((error) => {
				alert(error.message);
			});
	}

	renderSwitch(param) {
		switch (param) {
			case 1:
				return "Sedang diproses";
			case 2:
				return "Pemesanan Selesai";
			default:
				return "Belum diproses";
		}
	}
	render() {
		return (
			<>
				<div
					className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
					style={{
						minHeight: "600px",
						backgroundImage: "url(" + pic1 + ")",
						backgroundSize: "cover",
						backgroundPosition: "center top",
					}}
				>
					<span className="mask bg-gradient-default opacity-8" />

					<Container className="d-flex align-items-center" fluid>
						<Row>
							<Col lg="12" md="10">
								<h1 className="display-4 text-white">Kode Order: {this.props.orderCode}</h1>
								<h1 className="display-4 text-white ">Tanggal: {this.changeDateFormat(this.props.datePurc)}</h1>
								<h1 className="display-4 text-white mt-0 mb-5">Status: {this.renderSwitch(this.props.confirmStatus)}</h1>
								<Button onClick={(e) => this.handleFinishOrder(e, this.props.idOrder, this.props.confirmStatus)} disabled={this.props.confirmStatus === 2}>
									Selesai
								</Button>
								{this.props.confirmStatus === 0 ? (
									<Button color="success" onClick={(e) => this.handleConfirm(e, this.props.idOrder, this.props.confirmStatus)}>
										{this.state.loadingConfirm === true ? (
											<div>
												<i className="fas fa-circle-notch fa-spin"></i> Proses Pesanan
											</div>
										) : (
											"Proses Pesanan"
										)}
									</Button>
								) : this.props.confirmStatus === 1 ? (
									<Button color="danger" onClick={(e) => this.handleConfirm(e, this.props.idOrder, this.props.confirmStatus)}>
										{this.state.loadingConfirm === true ? (
											<div>
												<i className="fas fa-circle-notch fa-spin"></i> Batalkan Proses Pesanan
											</div>
										) : (
											"Batalkan Proses Pesanan"
										)}
									</Button>
								) : null}
							</Col>
						</Row>
						{this.state.alert}
					</Container>
				</div>
			</>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		user: state.user.username,
	};
};
export default connect(mapStateToProps, null)(UserHeader);
