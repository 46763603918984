import React from "react";
import { Route, Redirect } from "react-router-dom";
import CookiesService from "./CookiesService";

const GoHome = (props) => {
	return (
		<Redirect
			to={{
				pathname: "/login",
				state: {
					from: props.location,
				},
			}}
		/>
	);
};
var isAuthenticated = true;

const ProtectedRoute = ({ component: Component, ...rest }) => {
	const token = CookiesService.get("access_token");
	if (token) {
		isAuthenticated = true;
	} else {
		isAuthenticated = false;
	}
	return (
		<Route
			{...rest}
			render={(props) => {
				if (isAuthenticated) {
					return <Component {...props} />;
				} else {
					return <GoHome {...props} />;
				}
			}}
		/>
	);
};

export default ProtectedRoute;
