import React from "react";
import { Container, Row, Col } from "reactstrap";
import pic1 from "../../assets/img/brand/sentra2.jpg";
class UserHeader extends React.Component {
	render() {
		return (
			<>
				<div
					className="header pb-8  pt-lg-8 d-flex align-items-center"
					style={{
						minHeight: "600px",
						backgroundImage: "url(" + pic1 + ")",
						backgroundSize: "cover",
						backgroundPosition: "center top",
					}}
				>
					<span className="mask bg-gradient-default opacity-8" />
					<Container className="d-flex align-items-center" fluid>
						<Row>
							{this.props.statusPage === "Add" ? (
								<Col lg="12" md="10">
									<h1 className="display-3 text-white">Tambah Produk Baru</h1>
								</Col>
							) : (
								<Col lg="12" md="10">
									<h1 className="display-3 text-white">
										Ubah Diskripsi Produk
									</h1>
									{/* <p className="text-white mt-0 mb-1">Catatan: </p> */}
								</Col>
							)}
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

export default UserHeader;
