import React from "react";
import { Helmet } from "react-helmet";
import { Card, CardHeader, Container, Row } from "reactstrap";
import Header from "components/Headers/Header.js";
import DataTable from "../components/DataTables/TableRekap.js";
import CardBody from "reactstrap/lib/CardBody";
import axiosInstance from "../services/AxiosService";
import LoadingPic from "../assets/img/theme/ripple-loading.gif";

class Tables extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			listUps: null,
			listCable: null,
			list3M: null,
			listSchneider: null,
			listAccessories: null,
		};
	}

	fetchDataCable() {
		const url = "/orders/cable";
		axiosInstance
			.get(url)
			.then((response) => {
				this.setState({ listCable: response.data });
			})
			.catch((error) => {
				alert(error.message);
			});
	}

	fetchDataUps() {
		const url = "/orders/ups";
		axiosInstance
			.get(url)
			.then((response) => {
				this.setState({ listUps: response.data });
			})
			.catch((error) => {
				alert(error.message);
			});
	}

	fetchData3M() {
		const url = "/orders/3m";
		axiosInstance
			.get(url)
			.then((response) => {
				this.setState({ list3M: response.data });
			})
			.catch((error) => {
				alert(error.message);
			});
	}

	fetchDataSchneider() {
		const url = "/orders/schneider";
		axiosInstance
			.get(url)
			.then((response) => {
				this.setState({ listSchneider: response.data });
			})
			.catch((error) => {
				alert(error.message);
			});
	}
	fetchDataAccessories() {
		const url = "/orders/accessories";
		axiosInstance
			.get(url)
			.then((response) => {
				this.setState({ listAccessories: response.data });
			})
			.catch((error) => {
				alert(error.message);
			});
	}

	componentDidMount() {
		this.fetchDataCable();
		this.fetchDataUps();
		this.fetchData3M();
		this.fetchDataSchneider();
		this.fetchDataAccessories();
	}

	render() {
		return (
			<>
				<Helmet>
					<title>Rekap Data PT Sentratek Metalindo</title>
					<meta name="description" content="Admin PT Sentratek Metalindo " />
				</Helmet>
				<Header />
				<Container className="mt--7" fluid>
					<Row>
						<div className="col-12 mb-5">
							<Card className="shadow">
								<CardHeader className="border-0">
									<h3 className="mb-0">List Pemesanan Kabel</h3>
								</CardHeader>

								<CardBody>
									{this.state.listCable === null ? (
										<div className="text-center">
											<img src={LoadingPic} alt="..." />
										</div>
									) : (
										<DataTable product={this.state.listCable} />
									)}
								</CardBody>
							</Card>
						</div>

						<div className="col-12 mb-5">
							<Card className="shadow">
								<CardHeader className="border-0">
									<h3 className="mb-0">List Pemesanan UPS</h3>
								</CardHeader>

								<CardBody>
									{this.state.listUps === null ? (
										<div className="text-center">
											<img src={LoadingPic} alt="..." />
										</div>
									) : (
										<DataTable product={this.state.listUps} />
									)}
								</CardBody>
							</Card>
						</div>

						<div className="col-12 mb-5">
							<Card className="shadow">
								<CardHeader className="border-0">
									<h3 className="mb-0">List Pemesanan 3M</h3>
								</CardHeader>

								<CardBody>
									{this.state.list3M === null ? (
										<div className="text-center">
											<img src={LoadingPic} alt="..." />
										</div>
									) : (
										<DataTable product={this.state.list3M} />
									)}
								</CardBody>
							</Card>
						</div>

						<div className="col-12 mb-5">
							<Card className="shadow">
								<CardHeader className="border-0">
									<h3 className="mb-0">List Pemesanan Schneider</h3>
								</CardHeader>

								<CardBody>
									{this.state.listSchneider === null ? (
										<div className="text-center">
											<img src={LoadingPic} alt="..." />
										</div>
									) : (
										<DataTable product={this.state.listSchneider} />
									)}
								</CardBody>
							</Card>
						</div>

						<div className="col-12">
							<Card className="shadow">
								<CardHeader className="border-0">
									<h3 className="mb-0">List Pemesanan Accesories</h3>
								</CardHeader>

								<CardBody>
									{this.state.listAccessories === null ? (
										<div className="text-center">
											<img src={LoadingPic} alt="..." />
										</div>
									) : (
										<DataTable product={this.state.listAccessories} />
									)}
								</CardBody>
							</Card>
						</div>
					</Row>
				</Container>
			</>
		);
	}
}

export default Tables;
