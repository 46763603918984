import React from "react";
import { Button, Card, CardHeader, CardBody, Container, Row, Col, Table } from "reactstrap";
import LoadingPic from "../../assets/img/theme/ripple-loading.gif";
import UserHeader from "components/Headers/UserHeader.js";
import userPic from "../../assets/img/brand/teamwork.svg";
import SweetAlert from "react-bootstrap-sweetalert";
import axiosInstance from "../../services/AxiosService";
import { Helmet } from "react-helmet";

class Profile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			detailOrders: null,
			alert: null,
		};
	}

	fetchData() {
		const url = `/orders/${this.props.match.params.idProduct}?type=3m`;
		axiosInstance
			.get(url)
			.then((response) => {
				this.setState({ detailOrders: response.data });
				// console.log(response.data);
			})
			.catch((error) => {
				alert(error.message);
			});
	}

	componentDidMount() {
		this.fetchData();
	}

	handleNoted(e, noted) {
		e.preventDefault();
		const alertNoted = () => (
			<SweetAlert title="Catatan Pesanan" onConfirm={() => this.setState({ alert: null })}>
				<span>{noted == null ? <i style={{ opacity: "0.5" }}>Tidak ada catatan</i> : <span style={{ color: "red" }}>&quot;{noted}&quot;</span>}</span>
			</SweetAlert>
		);

		this.setState({
			alert: alertNoted(),
		});
	}

	convertPhone(phone) {
		let number = phone.substring(1);
		return `62${number}`;
	}

	formatRupiah(angka, prefix) {
		var number_string = String(angka)
				.replace(/[^,\d]/g, "")
				.toString(),
			split = number_string.split(","),
			sisa = split[0].length % 3,
			rupiah = split[0].substr(0, sisa),
			ribuan = split[0].substr(sisa).match(/\d{3}/gi);

		if (ribuan) {
			let separator = sisa ? "." : "";
			rupiah += separator + ribuan.join(".");
		}

		rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
		return prefix === undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
	}

	countDiscount(price, percent) {
		if (percent === null) {
			return price;
		} else {
			let potongan = (parseInt(price) * parseInt(percent)) / 100;
			return parseInt(price) - parseInt(potongan);
		}
	}

	render() {
		const ordered = this.state.detailOrders;
		return (
			<>
				<Helmet>
					<title>Detail Pesanan</title>
					<meta name="description" content="Admin PT Sentratek Metalindo " />
				</Helmet>
				{ordered === null ? (
					<div className="d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
						<img src={LoadingPic} alt="..." />
					</div>
				) : (
					<div>
						<UserHeader name={ordered.customer.name} company={ordered.customer.company} datePurc={ordered.ordering.created_at} confirmStatus={ordered.ordering.confirm} idOrder={ordered.ordering.id} orderCode={ordered.ordering.no_order} />
						<Container className="mt--7" fluid>
							<Row>
								<Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
									<Card className="card-profile shadow">
										<Row className="justify-content-center">
											<Col className="order-lg-2" lg="3">
												<div className="card-profile-image">
													<a href="#pablo" onClick={(e) => e.preventDefault()}>
														<img alt="..." className="rounded-circle" src={userPic} />
													</a>
												</div>
											</Col>
										</Row>
										<CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
											<div className="d-flex justify-content-between">
												<Button className="mr-4" color="info" href="#pablo" onClick={(e) => e.preventDefault()} size="sm">
													Customer
												</Button>
												<Button className="float-right" color="default" href="#pablo" onClick={(e) => e.preventDefault()} size="sm">
													Pelanggan
												</Button>
											</div>
										</CardHeader>
										<CardBody className="pt-0 pt-md-4">
											<div className="text-center mt-md-5">
												<h3>{ordered.customer.name}</h3>
												<div className="h5 font-weight-300">
													<i className="ni location_pin mr-2" />
													{ordered.customer.email}
												</div>
												<div>
													<i className="ni education_hat mr-2" />
													{ordered.customer.company}
												</div>
												<hr className="my-4" />
												<p>Phone/WhatsApp : {ordered.customer.phone}</p>
												{ordered.customer.whatsapp === 1 ? (
													<a
														href={`http://wa.me/${this.convertPhone(ordered.customer.phone)}`} // ini belum ganti 62
														target="_blank"
														rel="noreferrer"
													>
														Hubungi Melalui WhatsApp
													</a>
												) : null}
											</div>
										</CardBody>
									</Card>
								</Col>
								<Col className="order-xl-1" xl="8">
									<Card className="bg-secondary shadow">
										<CardHeader className="bg-white border-0">
											<Row className="align-items-center">
												<Col xs="8">
													<h3 className="mb-0 text-uppercase">Order List {ordered.ordering.type}</h3>
												</Col>
												<Col className="text-right" xs="4">
													<Button color="primary" href="#pablo" onClick={(e) => e.preventDefault()} size="sm">
														Produk Pesanan
													</Button>
												</Col>
											</Row>
										</CardHeader>
										<CardBody>
											<Table className="align-items-center table-flush" responsive>
												<thead className="thead-light">
													<tr>
														<th scope="col">Id</th>
														<th scope="col">Nama Produk</th>
														<th scope="col">Deskripsi Produk</th>
														<th scope="col">Harga Bersih</th>
														<th scope="col">Catatan</th>
														<th scope="col"></th>
													</tr>
												</thead>
												<tbody>
													{Object.values(ordered.listProducts).map((item) => (
														<tr key={item.id}>
															<td>
																<b>{item.id}</b>
															</td>
															<td className="font-weight-bold">{item.product_name}</td>
															<td>
																<div className="row">
																	<div className="col">T. C.:</div>
																	<div className="col font-weight-bold">{item.conductor_range !== null ? <span>{item.conductor_range}</span> : "-"}</div>
																</div>
																<div className="row">
																	<div className="col ">Voltage:</div>
																	<div className="col font-weight-bold">{item.voltage_range !== null ? <span>{item.voltage_range}</span> : "-"}</div>
																</div>

																<div className="row">
																	<div className="col ">Jumlah :</div>
																	<div className="col font-weight-bold">{item.quantity !== null ? item.quantity : "-"}</div>
																</div>
															</td>
															<td className="font-weight-bold">{item.price !== null ? <div>{item.discount !== null ? <div>{this.formatRupiah(this.countDiscount(item.price, item.discount), "Rp. ") + ` (-${item.discount}%)`}</div> : item.cutOff !== null ? <div>{this.formatRupiah(this.countCutOff(item.price, item.cutOff), "Rp. ") + ` (- ${this.formatRupiah(item.cutOff, "Rp.")})`}</div> : "-"} </div> : "-"}</td>

															<td>
																<Button onClick={(e) => this.handleNoted(e, item.noted)} size="sm">
																	Cek Catatan
																</Button>
															</td>
														</tr>
													))}
												</tbody>
											</Table>
											{this.state.alert}
										</CardBody>
									</Card>
								</Col>
							</Row>
						</Container>{" "}
					</div>
				)}
			</>
		);
	}
}

export default Profile;
