import React from "react";
import classnames from "classnames";
import Chart from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import {
	Card,
	CardHeader,
	CardBody,
	NavItem,
	NavLink,
	Nav,
	Row,
	Col,
} from "reactstrap";
import {
	chartOptions,
	parseOptions,
	chartExample1,
	chartExample2,
} from "variables/charts.js";
import axiosInstance from "../../services/AxiosService";
import LoadingPic from "../../assets/img/theme/ripple-loading.gif";

const months = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec",
];
class Index extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dataChart1: {
				labels: months,
				datasets: [
					{
						data: null,
					},
				],
			},
			dataChart2: {
				labels: months,
				datasets: [
					{
						data: null,
					},
				],
			},
		};
		if (window.Chart) {
			parseOptions(Chart, chartOptions());
		}
	}

	fetchDataSold() {
		const url = "/chart/sold";
		axiosInstance
			.get(url)
			.then((response) => {
				this.setState({
					dataChart1: {
						labels: months,
						datasets: [
							{
								data: response.data,
							},
						],
					},
				});
			})
			.catch((error) => {
				alert(error.message);
			});
	}

	fetchDataOrders() {
		const url = "/chart/orders";
		axiosInstance
			.get(url)
			.then((response) => {
				this.setState({
					dataChart2: {
						labels: months,
						datasets: [
							{
								data: response.data,
							},
						],
					},
				});
			})
			.catch((error) => {
				alert(error.message);
			});
	}

	componentDidMount() {
		this.fetchDataSold();
		this.fetchDataOrders();
	}

	render() {
		return (
			<Row>
				<Col className="mb-5 mb-xl-0" xl="8">
					<Card className="bg-gradient-default shadow">
						<CardHeader className="bg-transparent">
							<Row className="align-items-center">
								<div className="col">
									<h6 className="text-uppercase text-light ls-1 mb-1">
										Overview
									</h6>
									<h2 className="text-white mb-0">Total Pemesanan</h2>
								</div>
								<div className="col">
									<Nav className="justify-content-end" pills>
										<NavItem>
											<NavLink
												className={classnames("py-2 px-3", {
													active: this.state.activeNav === 1,
												})}
												href="#pablo"
												// onClick={(e) => this.toggleNavs(e, 1)}
											>
												<span className="d-none d-md-block">Month</span>
												<span className="d-md-none">M</span>
											</NavLink>
										</NavItem>
									</Nav>
								</div>
							</Row>
						</CardHeader>
						<CardBody>
							{this.state.dataChart1.datasets[0].data === null ? (
								<div className="text-center">
									<img src={LoadingPic} alt="..." />
								</div>
							) : (
								<div className="chart">
									<Line
										data={this.state.dataChart1}
										options={chartExample1.options}
										getDatasetAtEvent={(e) => console.log(e)}
									/>
								</div>
							)}
						</CardBody>
					</Card>
				</Col>

				<Col xl="4">
					<Card className="shadow">
						<CardHeader className="bg-transparent">
							<Row className="align-items-center">
								<div className="col">
									<h6 className="text-uppercase text-muted ls-1 mb-1">
										Performance
									</h6>
									<h2 className="mb-0">Produk Terpesan</h2>
								</div>
							</Row>
						</CardHeader>
						<CardBody>
							{this.state.dataChart2.datasets[0].data === null ? (
								<div className="text-center">
									<img src={LoadingPic} alt="..." />
								</div>
							) : (
								<div className="chart">
									<Bar
										data={this.state.dataChart2}
										options={chartExample2.options}
									/>
								</div>
							)}
						</CardBody>
					</Card>
				</Col>
			</Row>
		);
	}
}

export default Index;
