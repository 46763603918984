import React from "react";
import Chart from "chart.js";
import { Link } from "react-router-dom";
import { Button, Card, CardHeader, CardBody, Table, Container, Row, Col } from "reactstrap";
import { chartOptions, parseOptions } from "variables/charts.js";
import axiosInstance from "../services/AxiosService";
import LoadingPic from "../assets/img/theme/ripple-loading.gif";
import Header from "components/Headers/Header.js";
import moment from "moment";
import ChartStatus from "components/Chart/Chart";
import { Helmet } from "react-helmet";

class Index extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activeNav: 1,
			chartExample1Data: "data1",
			listNewOrder: null,
		};
		if (window.Chart) {
			parseOptions(Chart, chartOptions());
		}
	}

	fetchData() {
		const url = "/ordering/new";
		axiosInstance
			.get(url)
			.then((response) => {
				// console.log(response.data);
				this.setState({ listNewOrder: response.data });
			})
			.catch((error) => {
				alert(error.message);
			});
	}

	checkPesanan(e) {
		e.preventDefault();
		this.setState({ listNewOrder: null });
		this.fetchData();
	}
	changeDateFormat = (date) => {
		const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
		let dateNew = moment(date).local().format("DD/MM/YYYY");
		var splitted = dateNew.split("/");
		// let time = moment(date).local().format("HH:mm");
		return `${splitted[0]} ${monthNames[splitted[1] - 1]} ${splitted[2]}`;
	};

	componentDidMount() {
		this.fetchData();
	}
	render() {
		return (
			<>
				<Helmet>
					<title>PT Sentratek Metalindo</title>
					<meta name="description" content="Admin PT Sentratek Metalindo " />
				</Helmet>
				<Header />

				<Container className="mt--8" fluid>
					<Row className="mt-5 mb-5 mb-xl-5 mb-xs-2">
						<Col className="mb-5 mb-xl-0" xl="12">
							<Card className="shadow" style={{ minHeight: "500px" }}>
								<CardHeader className="border-0">
									<Row className="align-items-center">
										<div className="col">
											<h3 className="mb-0">Pesanan Terbaru</h3>
										</div>
										<div className="col text-right">
											<Button color="danger" onClick={(e) => this.checkPesanan(e)} size="sm">
												Pesanan Terbaru
											</Button>
										</div>
									</Row>
								</CardHeader>
								<CardBody>
									{this.state.listNewOrder === null ? (
										<div className="text-center">
											<img src={LoadingPic} alt="..." />
										</div>
									) : (
										<Table className="align-items-center table-flush" responsive>
											<thead className="thead-light">
												<tr>
													<th scope="col">Kode</th>
													<th scope="col">Name</th>
													<th scope="col">Company</th>
													<th scope="col">Date</th>
													<th scope="col">Action</th>
												</tr>
											</thead>

											<tbody>
												{Object.values(this.state.listNewOrder).map((item) => (
													<tr key={item.id}>
														<td>{item.no_order}</td>
														<td className="text-capitalize">{item.customer.name}</td>
														<td>{item.customer.company}</td>
														<td>{this.changeDateFormat(item.created_at)}</td>
														<td>
															<Button color="primary" size="sm" tag={Link} to={`/detail-order/${item.no_order}`}>
																Proses
															</Button>
														</td>
													</tr>
												))}
											</tbody>
										</Table>
									)}
								</CardBody>
							</Card>
						</Col>
					</Row>

					<ChartStatus />
				</Container>
			</>
		);
	}
}

export default Index;
