import "../../assets/css/dataTables.bootstrap4.min.css";
import React, { Component } from "react";
import { Button, Row, Col, Input, Modal, ModalHeader, ModalBody } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import axiosInstance from "../../services/AxiosService";
import "datatables.net-responsive";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.print";

import $ from "jquery";
import JSZip from "jszip";
window.JSZip = JSZip;

export default class Tbl extends Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			taskDone: false,
			newEmail: {
				id: "",
				email: "",
				name: "",
				company: "",
				sales: "",
			},
			showModal: false,
		};
	}

	componentDidMount() {
		this.$el = $(this.el);
		this.$el.DataTable({
			dom: "lBfrtip",
			paging: true,
			scrollY: 600,
			select: true,
			searching: true,
			responsive: {
				details: {
					type: "column",
				},
			},
			columnDefs: [],
			order: [1, "desc"],
			ordering: true,

			buttons: [
				{
					extend: "excel",
					text: "Save To Excel",
					fileName: "BroadcastEmail.xlsx",
					exportOptions: {
						modifier: {
							page: "current",
						},
					},
				},
				{
					extend: "pdf",
					text: "Save To pdf",
					fileName: "BroadcastEmail.pdf",
					exportOptions: {
						modifier: {
							page: "current",
						},
					},
				},
			],
			columns: [{ title: "Name" }, { title: "Email" }, { title: "Sales" }, { title: "Company" }, { title: "Aksi" }],
		});
	}

	setModalShow(e, status, item) {
		e.preventDefault();
		if (status == true) {
			this.setState({
				showModal: true,
				newEmail: {
					id: item.id,
					email: item.email,
					name: item.name,
					company: item.company,
					sales: item.sales,
				},
			});
		} else {
			this.setState({
				showModal: false,
				newEmail: {
					id: "",
					email: "",
					name: "",
					company: "",
					sales: "",
				},
			});
		}
	}

	handleDeleteProduct(e, idEmail) {
		e.preventDefault();
		const alertDelete = () => (
			<SweetAlert warning showCancel confirmBtnText="Ya, Hapus Email" confirmBtnBsStyle="danger" cancelBtnText="Batal" title="Apakah Anda Yakin?" onConfirm={() => this.deleteEmail(idEmail)} onCancel={() => this.setState({ alert: null })} focusCancelBtn>
				Email yang dihapus tidak dapat dikembalikan!
			</SweetAlert>
		);

		this.setState({
			alert: alertDelete(),
		});
	}

	deleteEmail(idEmail) {
		const url = `/email/delete/${idEmail}`;
		axiosInstance
			.post(url)
			.then((response) => {
				if (response.data === "Success") {
					this.handleSuccess("Email Berhasil Dihapus");
				}
			})
			.catch((error) => {
				alert(error.message);
			});
	}

	handleSuccess(message) {
		const alertSuccess = () => (
			<SweetAlert success title="Berhasil!" onConfirm={() => this.closeTheAlert()}>
				{message}
			</SweetAlert>
		);
		this.setState({
			alert: alertSuccess(),
		});
	}

	closeTheAlert() {
		this.setState({
			alert: null,
			taskDone: true,
		});
	}

	async handleEditEmail(e, id, nameOld, mailOld) {
		e.preventDefault();
		let alertLogout = () => (
			<SweetAlert title="Tambah Email" showCancel cancelBtnBsStyle="danger" confirmBtnText="Simpan" cancelBtnText="Batal" onConfirm={() => this.editEmail(id)} onCancel={() => this.setState({ alert: null, newMail: "", newName: "" })} dependencies={[this.state.newName]}>
				<div>
					<div className="text-left">
						<p>Nama lama : {nameOld}</p>
						<p>Email lama : {mailOld}</p>
					</div>

					<Input
						className="form-control"
						id="input-productName"
						placeholder="Name"
						type="text"
						onChange={(e) =>
							this.setState({
								newName: e.target.value,
							})
						}
					/>

					<br />
					<Input
						className="form-control"
						id="input-productName"
						placeholder="Email"
						type="email"
						onChange={(e) =>
							this.setState({
								newMail: e.target.value,
							})
						}
					/>
				</div>
			</SweetAlert>
		);

		this.setState({
			alert: alertLogout(),
		});
	}

	editEmail(e, id) {
		e.preventDefault();
		const url = `/email/edit/${id}`;
		const dataToPost = this.state.newEmail;
		axiosInstance
			.post(url, dataToPost)
			.then((response) => {
				if (response.data === "Success") {
					this.handleSuccess("Email Berhasil Diubah");
				} else {
					this.handleError("Proses Gagal");
				}
			})
			.catch((error) => {
				alert(error.message);
			});
	}
	handleError(message) {
		const alertError = () => (
			<SweetAlert
				danger
				title="Gagal!"
				onConfirm={() =>
					this.setState({
						alert: false,
					})
				}
			>
				{message}
			</SweetAlert>
		);
		this.setState({
			alert: alertError(),
		});
	}

	handleSendEmail(e, id, name, email) {
		e.preventDefault();
		const dataToPost = { name: name, email: email };
		const url = `/email/send/${id}`;
		axiosInstance
			.post(url, dataToPost)
			.then((response) => {
				console.log(response);
				if (response.data === "Success") {
					this.handleSuccessSendEmail("Email Telah Dikirim");
				} else {
					this.handleError("Proses tidak dapat dilanjutkan");
				}
			})
			.catch((error) => {
				console.log(error.message);
				alert(error.message);
			});
	}

	handleSuccessSendEmail(message) {
		const alertSuccess = () => (
			<SweetAlert
				success
				title="Berhasil!"
				onConfirm={() =>
					this.setState({
						alert: null,
					})
				}
			>
				{message}
			</SweetAlert>
		);
		this.setState({
			alert: alertSuccess(),
		});
	}

	render() {
		// console.log(this.state);
		if (this.state.taskDone === true) {
			this.props.fetchListEmail();
			this.props.fetchHistoryEmail();
			this.props.fetchSummaryEmail();
		}
		const emails = this.props.emails;

		return (
			<div>
				<table className="display table table-bordered text-center nowrap" width="100%" ref={(el) => (this.el = el)}>
					<tbody>
						{Object.values(emails).map((item) => (
							<tr key={item.id}>
								<td>{item.name}</td>
								<td>{item.email}</td>
								<td>{item.sales}</td>
								<td>{item.company}</td>

								<td style={{ padding: "15px" }}>
									<Row>
										<Col>
											<Button className="w-100" size="sm" color="success" onClick={(e) => this.handleSendEmail(e, item.id, item.name, item.email)}>
												Kirim Email
											</Button>
										</Col>
										<div className="w-100 mt-1"></div>
										<Col>
											<Button className="w-100" size="sm" color="primary" onClick={(e) => this.setModalShow(e, true, item)}>
												Edit
											</Button>
										</Col>
										<div className="w-100 mt-1"></div>
										<Col>
											<button className="btn btn-sm btn-danger w-100" onClick={(e) => this.handleDeleteProduct(e, `${item.id}`)}>
												Delete
											</button>
										</Col>
									</Row>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				{this.state.alert}
				<Modal isOpen={this.state.showModal} toggle={(e) => this.setModalShow(e, false)} centered={true}>
					<ModalHeader toggle={(e) => this.setModalShow(e, false)}>
						<h2>Ubah Email</h2>
					</ModalHeader>
					<ModalBody>
						<form onSubmit={(e) => this.editEmail(e, this.state.newEmail.id)}>
							<Input
								className="form-control mb-3"
								id="input-productName"
								placeholder="Name"
								type="text"
								value={this.state.newEmail.name}
								onChange={(e) =>
									this.setState({
										newEmail: {
											...this.state.newEmail,
											name: e.target.value,
										},
									})
								}
							/>
							<Input
								className="form-control mb-3"
								id="input-productName"
								placeholder="Email"
								type="email"
								value={this.state.newEmail.email}
								onChange={(e) =>
									this.setState({
										newEmail: {
											...this.state.newEmail,
											email: e.target.value,
										},
									})
								}
							/>
							<Input
								className="form-control mb-3"
								id="input-productName"
								placeholder="Nama Sales (Opsional)"
								type="text"
								value={this.state.newEmail.sales}
								onChange={(e) =>
									this.setState({
										newEmail: {
											...this.state.newEmail,
											sales: e.target.value,
										},
									})
								}
							/>
							<Input
								className="form-control mb-3"
								id="input-productName"
								placeholder="Nama Perusahaan (Opsional)"
								type="text"
								value={this.state.newEmail.company}
								onChange={(e) =>
									this.setState({
										newEmail: {
											...this.state.newEmail,
											company: e.target.value,
										},
									})
								}
							/>
							<div className="fa-pull-right">
								<Button color="danger" onClick={(e) => this.setModalShow(e, false)}>
									Batal
								</Button>{" "}
								<Button type="submit" color="primary">
									Simpan
								</Button>
							</div>
						</form>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}
