import * as actionType from "../actions/ActionTypes";
import update from "react-addons-update";

const initialState = {
	user: {
		username: null,
	},
};

const UserReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionType.LOGIN_SUCCESS:
			return update(state, {
				user: {
					username: { $set: action.username },
				},
			});

		default:
			return state;
	}
};

export default UserReducer;
