import Index from "views/Index.js";
import History from "views/History.js";
import Customer from "views/Customer.js";
import ProductCable from "views/ProductCable.js";
import ProductUPS from "views/ProductUPS.js";
import Product3M from "views/Product3M.js";
import ProductSchneider from "views/ProductSchneider.js";
import ProductAccessories from "views/ProductAccessories.js";

import Rekap from "views/Rekap.js";
import SendEmail from "views/SendEmail.js";

var routes = [
	{
		path: "home",
		name: "Dashboard",
		icon: "ni ni-tv-2 text-primary",
		component: Index,
		layout: "/",
	},
	{
		path: "list-cable",
		name: "Cable",
		icon: "ni ni-shop text-yellow",
		component: ProductCable,
		layout: "/",
	},
	{
		path: "list-ups",
		name: "UPS",
		icon: "ni ni-building text-blue",
		component: ProductUPS,
		layout: "/",
	},
	{
		path: "list-3m",
		name: "3M",
		icon: "ni ni-spaceship text-green",
		component: Product3M,
		layout: "/",
	},
	{
		path: "list-schneider",
		name: "Schneider",
		icon: "ni ni-box-2 text-purple",
		component: ProductSchneider,
		layout: "/",
	},
	{
		path: "list-accessories",
		name: "Accessories",
		icon: "ni ni-bag-17 text-info",
		component: ProductAccessories,
		layout: "/",
	},
	{
		path: "history",
		name: "History",
		icon: "ni ni-cart text-orange",
		component: History,
		layout: "/",
	},
	{
		path: "customers",
		name: "Customer",
		icon: "ni ni-badge text-red",
		component: Customer,
		layout: "/",
	},
	{
		path: "rekap",
		name: "Rekap Data",
		icon: "ni ni-archive-2 text-green",
		component: Rekap,
		layout: "/",
	},
	{
		path: "send-email",
		name: "Broadcast Email",
		icon: "ni ni-email-83 text-blue",
		component: SendEmail,
		layout: "/",
	},
];
export default routes;
