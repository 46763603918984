export const loadState = () => {
	try {
		const serializedState = sessionStorage.getItem("sentratekState");
		if (serializedState === null) {
			return undefined;
		}
		return JSON.parse(serializedState);
	} catch (err) {
		return undefined;
	}
};

export const saveState = (state) => {
	try {
		sessionStorage.setItem("sentratekState", JSON.stringify(state.getState()));
	} catch (err) {}
};
