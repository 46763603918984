import React, { Component } from "react";
import "../assets/css/Login.css";
import { Button, FormGroup, Input, InputGroupAddon, InputGroupText, InputGroup, Row, Form } from "reactstrap";
import loginPic from "../assets/img/icons/login.svg";
import axiosInstance from "../services/AxiosService";
import { withRouter } from "react-router-dom";
import CookiesService from "../services/CookiesService";
import { connect } from "react-redux";
import { loginSuccess } from "../redux/actions/Action";
function FailureMessage() {
	return (
		<InputGroup style={{ color: "red", marginTop: "10px", marginBottom: "0" }} className="d-flex justify-content-center">
			<InputGroupAddon addonType="prepend" className="d-flex align-items-center">
				<i className="fa fa-exclamation-triangle mr-1" aria-hidden="true"></i>
				<span>Username atau Password Salah!</span>
			</InputGroupAddon>
		</InputGroup>
	);
}

const expiresAt = 60 * 24;

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			username: "",
			password: "",
			rememberMe: false,
			responseLoginFailed: false,
			loadingAuth: false,
		};
	}

	handleChecked() {
		this.setState({ rememberMe: !this.state.rememberMe });
	}

	handleLogin(e) {
		e.preventDefault();
		this.setState({ loadingAuth: true });
		const url = "/login";
		const credentials = {
			username: this.state.username,
			password: this.state.password,
		};

		axiosInstance
			.post(url, credentials)
			.then((response) => {
				this.props.loginSuccess(this.state.username);
				this.handelLoginSuccess(response.data, this.state.rememberMe);
				this.props.history.push("/admin");
			})
			.catch((error) => {
				if (error.response.status === 400) {
					this.setState({ responseLoginFailed: true, loadingAuth: false });
				}
				alert(error.message);
				// console.log(error.message);
			});
	}
	handelLoginSuccess(response, remember) {
		if (!remember) {
			const options = { path: "/" };
			CookiesService.set("access_token", response.access_token, options);
			return true;
		}

		let date = new Date();
		date.setTime(date.getTime() + expiresAt * 60 * 1000);
		const options = { path: "/", expires: date };
		CookiesService.set("access_token", response.access_token, options);
		return true;
	}

	handleInputUsername(e) {
		this.setState({ username: e.target.value, responseLoginFailed: false });
	}

	handleInputPassword(e) {
		this.setState({ password: e.target.value, responseLoginFailed: false });
	}

	render() {
		return (
			<div className="d-flex align-items-center justify-content-center mt-5" style={{ height: "80vh" }}>
				<div className="card-login align-self-center ">
					<Row className=" align-items-center p-5" style={{ height: "100%" }}>
						<div className="col-sm d-flex align-items-center justify-content-center box-img-login">
							<img src={loginPic} className="img-fluid align-self-center " alt="" />
						</div>
						<div className="col-sm pt-2">
							<div className="text-center m-auto">
								<h2>Hai, Apa kabar?</h2>
								<h3 className="text-overflow">Masuk untuk lanjut</h3>
								<Form onSubmit={(e) => this.handleLogin(e)}>
									<FormGroup className="mt-3">
										<InputGroup>
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<i className="fa fa-user" aria-hidden="true"></i>
												</InputGroupText>
											</InputGroupAddon>
											<Input placeholder="Username" type="text" onChange={(e) => this.handleInputUsername(e)} value={this.state.username} required />
										</InputGroup>
									</FormGroup>

									<FormGroup className="mt-2">
										<InputGroup>
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<i className="fa fa-lock" aria-hidden="true"></i>
												</InputGroupText>
											</InputGroupAddon>
											<Input placeholder="Password" type="password" onChange={(e) => this.handleInputPassword(e)} value={this.state.password} required />
										</InputGroup>
									</FormGroup>
									<div className="custom-control custom-checkbox mb-3 text-left">
										<input className="custom-control-input" id="rememberMe" type="checkbox" onChange={() => this.handleChecked()} />
										<label className="custom-control-label" htmlFor="rememberMe">
											Remember Me
										</label>
									</div>
									<Button color="primary" size="md" outline type="submit" className="rounded w-100">
										{this.state.loadingAuth === true ? (
											<div>
												<i className="fas fa-circle-notch fa-spin"></i> Masuk{" "}
											</div>
										) : (
											"Masuk"
										)}
									</Button>

									{this.state.responseLoginFailed === true ? <FailureMessage /> : null}
								</Form>
							</div>
						</div>
					</Row>
				</div>
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		loginSuccess: (username) => dispatch(loginSuccess(username)),
	};
};
export default connect(null, mapDispatchToProps)(withRouter(Login));
