import React from "react";
import { Card, CardHeader, CardBody, Container, Row } from "reactstrap";
import Header from "components/Headers/Header.js";
import LoadingPic from "../assets/img/theme/ripple-loading.gif";
import axiosInstance from "../services/AxiosService";
import DataTable from "../components/DataTables/TableHistories.js";
import { Helmet } from "react-helmet";

class Orders extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			listHistoryOrder: null,
			taskDone: false,
		};
	}

	fetchData() {
		const url = "/ordering";
		axiosInstance
			.get(url)
			.then((response) => {
				this.setState({ listHistoryOrder: response.data });
			})
			.catch((error) => {
				alert(error.message);
			});
	}

	componentDidMount() {
		this.fetchData();
	}

	render() {
		return (
			<>
				<Helmet>
					<title>Riwayat Pesanan PT Sentratek Metalindo</title>
					<meta name="description" content="Admin PT Sentratek Metalindo " />
				</Helmet>
				<Header />
				<Container className=" mt--7" fluid>
					<Row>
						<div className=" col">
							<Card className=" shadow">
								<CardHeader className=" bg-transparent">
									<h3 className=" mb-0">List History</h3>
								</CardHeader>
								<CardBody>
									{this.state.listHistoryOrder === null ? (
										<div className="text-center">
											<img src={LoadingPic} alt="..." />
										</div>
									) : (
										<DataTable listOrders={this.state.listHistoryOrder} />
									)}
								</CardBody>
							</Card>
						</div>
					</Row>
				</Container>
			</>
		);
	}
}

export default Orders;
