import "../../assets/css/dataTables.bootstrap4.min.css";
import React, { Component } from "react";
import moment from "moment";

import "datatables.net-responsive";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.print";

import $ from "jquery";
import JSZip from "jszip";
window.JSZip = JSZip;

export default class Tbl extends Component {
	componentDidMount() {
		this.$el = $(this.el);
		this.$el.DataTable({
			dom: "lBfrtip",
			paging: true,
			scrollY: 400,
			select: true,
			searching: true,
			responsive: {
				details: {
					type: "column",
				},
			},
			columnDefs: [
				{
					className: "dtr-control",
					orderable: false,
					targets: 0,
				},
			],
			order: [1, "desc"],
			ordering: true,

			buttons: [
				{
					extend: "excel",
					text: "Save To Excel",
					fileName: "data.xlsx",
					exportOptions: {
						modifier: {
							page: "current",
						},
					},
				},
				{
					extend: "pdf",
					text: "Save To pdf",
					fileName: "data.pdf",
					exportOptions: {
						modifier: {
							page: "current",
						},
					},
				},
			],
			columns: [{ title: "" }, { title: "Tgl" }, { title: "Name" }, { title: "Email" }, { title: "Company" }, { title: "Phone" }, { title: "Product" }, { title: "Quant." }, { title: "Price" }, { title: "Note" }],
		});
	}

	convertPhone(phone) {
		let number = phone.substring(1);
		return `62${number}`;
	}

	changeDateFormat = (date) => {
		let dateNew = moment(date).local().format("DD/MM/YYYY HH:mm");
		return dateNew;
	};

	countDiscount(price, percent) {
		if (percent === null) {
			return price;
		} else {
			let potongan = (parseInt(price) * parseInt(percent)) / 100;
			return parseInt(price) - parseInt(potongan);
		}
	}

	formatRupiah(angka, prefix) {
		var number_string = String(angka)
				.replace(/[^,\d]/g, "")
				.toString(),
			split = number_string.split(","),
			sisa = split[0].length % 3,
			rupiah = split[0].substr(0, sisa),
			ribuan = split[0].substr(sisa).match(/\d{3}/gi);

		if (ribuan) {
			let separator = sisa ? "." : "";
			rupiah += separator + ribuan.join(".");
		}

		rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
		return prefix === undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
	}

	render() {
		const product = this.props.product;
		return (
			<div>
				<table className="display table table-bordered text-center nowrap" width="100%" ref={(el) => (this.el = el)}>
					<tbody>
						{Object.values(product).map((item) => (
							<tr key={item.id}>
								<td></td>
								<td>{this.changeDateFormat(item.created_at)}</td>
								<td>{item.customer.name}</td>
								<td>{item.customer.email}</td>
								<td>{item.customer.company}</td>
								<td>{item.customer.phone}</td>
								<td>{item.product_name}</td>
								<td>{item.quantity}</td>
								<td>{item.price !== null ? <div>{this.formatRupiah(this.countDiscount(item.price, item.discount), "Rp. ")}</div> : "-"}</td>
								<td>{item.noted}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	}
}
