import "../../assets/css/dataTables.bootstrap4.min.css";
import React, { Component } from "react";

import "datatables.net-responsive";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.print";

import $ from "jquery";
import JSZip from "jszip";
window.JSZip = JSZip;

export default class Tbl extends Component {
	componentDidMount() {
		this.$el = $(this.el);
		this.$el.DataTable({
			dom: "lBfrtip",
			paging: true,
			scrollY: 400,
			select: true,
			searching: true,
			responsive: true,
			ordering: true,

			buttons: [
				{
					extend: "excel",
					text: "Save To Excel",
					fileName: "data.xlsx",
					exportOptions: {
						modifier: {
							page: "current",
						},
					},
				},
				{
					extend: "pdf",
					text: "Save To pdf",
					fileName: "data.pdf",
					exportOptions: {
						modifier: {
							page: "current",
						},
					},
				},
			],
			columns: [{ title: "Name" }, { title: "Email" }, { title: "Company" }, { title: "Phone/WhatsApp" }, { title: "Action", width: "20%" }],
			columnDefs: [
				{ responsivePriority: 1, targets: 0 },
				{ responsivePriority: 2, targets: 1 },
				{ responsivePriority: 5, targets: -1 },
			],
		});
	}

	convertPhone(phone) {
		let number = phone.substring(1);
		return `62${number}`;
	}

	render() {
		const listCustomer = this.props.listCustomer;
		return (
			<div>
				<table className="display table table-bordered text-center nowrap" width="100%" ref={(el) => (this.el = el)}>
					<tbody>
						{Object.values(listCustomer).map((item) => (
							<tr key={item.id}>
								<td>{item.name}</td>
								<td>{item.email}</td>
								<td>{item.company}</td>
								<td>{item.phone}</td>
								<td style={{ padding: "15px" }}>
									{item.whatsapp === 1 ? (
										<a className="btn btn-sm btn-primary" href={`http://wa.me/${this.convertPhone(item.phone)}`} target="_blank" rel="noreferrer">
											Hubungi Via WA
										</a>
									) : (
										"-"
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	}
}
