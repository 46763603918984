import "../../assets/css/dataTables.bootstrap4.min.css";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Button } from "reactstrap";
import "datatables.net-responsive";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.print";

import $ from "jquery";
import JSZip from "jszip";
window.JSZip = JSZip;

export default class Tbl extends Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			taskDone: false,
		};
	}

	changeDateFormat = (date) => {
		let dateNew = moment(date).local().format("DD/MM/YYYY HH:mm");
		return dateNew;
	};

	componentDidMount() {
		this.$el = $(this.el);
		this.$el.DataTable({
			dom: "lBfrtip",
			paging: true,
			scrollY: 400,
			select: true,
			searching: true,
			responsive: true,
			order: [[4, "desc"]],
			buttons: [
				{
					extend: "excel",
					text: "Save To Excel",
					fileName: "data.xlsx",
					exportOptions: {
						modifier: {
							page: "current",
						},
					},
				},
				{
					extend: "pdf",
					text: "Save To pdf",
					fileName: "data.pdf",
					exportOptions: {
						modifier: {
							page: "current",
						},
					},
				},
			],
			columns: [{ title: "Kode" }, { title: "Name" }, { title: "Perusahaan" }, { title: "Jenis Produk" }, { title: "Tanggal Pemesanan" }, { title: "Status Pemesanan" }, { title: "Aksi", width: "10%" }],
			columnDefs: [
				{ responsivePriority: 1, targets: 0 },
				{ responsivePriority: 2, targets: 1 },
				{ responsivePriority: 7, targets: -1 },
			],
		});
	}

	render() {
		// console.log(this.props.listOrders);
		return (
			<div>
				<table className="display table table-bordered text-center nowrap" width="100%" ref={(el) => (this.el = el)}>
					<tbody>
						{Object.values(this.props.listOrders).map((item) => (
							<tr key={item.id}>
								<td>{item.no_order}</td>
								<td>{item.customer.name}</td>
								<td>{item.customer.company}</td>
								<td>
									<Button
										size="sm"
										className={(() => {
											switch (item.type) {
												case "cable":
													return "text-uppercase bg-yellow text-white";
												case "ups":
													return "text-uppercase bg-info text-white";
												case "3m":
													return "text-uppercase bg-green text-white";
												default:
													return "text-uppercase bg-purple text-white";
											}
										})()}
									>
										{item.type}
									</Button>
								</td>
								<td>{this.changeDateFormat(item.created_at)}</td>
								<td>
									{item.confirm === 1 ? (
										<i>Sedang diproses</i>
									) : item.confirm === 0 ? (
										<b>
											<i>Belum dikonfirmasi</i>
										</b>
									) : (
										"Selesai"
									)}
								</td>
								<td style={{ padding: "15px" }}>
									<Button color="success" size="sm" tag={Link} to={`/detail-order/${item.no_order}/${item.type}/${item.id}`}>
										Open
									</Button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	}
}
