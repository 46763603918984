import React from "react";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import axiosInstance from "../../services/AxiosService";
import LoadingPic from "../../assets/img/theme/ripple-loading.gif";
const since = "Since January 2021";

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			status: null,
		};
	}

	fetchData() {
		const url = "/orders/count";
		axiosInstance
			.get(url)
			.then((response) => {
				this.setState({ status: response.data });
			})
			.catch((error) => {
				alert(error.message);
			});
	}
	componentDidMount() {
		this.fetchData();
	}

	render() {
		// console.log(this.state.status);
		return (
			<>
				<div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
					<Container fluid>
						<div className="header-body">
							<Row>
								<Col lg="6" xl="3" className="mb-3">
									<Card className="card-stats mb-4 mb-xl-0">
										<CardBody>
											{this.state.status === null ? (
												<div className="text-center">
													<img src={LoadingPic} width="50" alt="..." />
												</div>
											) : (
												<Row>
													<div className="col">
														<CardTitle tag="h5" className="text-uppercase text-muted mb-0">
															Pesanan Baru
														</CardTitle>
														<span className="h2 font-weight-bold mb-0">{this.state.status.totalNewOrder}</span>
													</div>
													<Col className="col-auto">
														<div className="icon icon-shape bg-danger text-white rounded-circle shadow">
															<i className="ni ni-bag-17" />
														</div>
													</Col>
												</Row>
											)}
											<p className="mt-1 mb-0 text-muted text-sm">
												<span className="text-nowrap">{since}</span>
											</p>
										</CardBody>
									</Card>
								</Col>
								<Col lg="6" xl="3" className="mb-3">
									<Card className="card-stats mb-4 mb-xl-0">
										<CardBody>
											{this.state.status === null ? (
												<div className="text-center">
													<img src={LoadingPic} width="50" alt="..." />
												</div>
											) : (
												<Row>
													<div className="col">
														<CardTitle tag="h5" className="text-uppercase text-muted mb-0">
															Pesanan Selesai
														</CardTitle>
														<span className="h2 font-weight-bold mb-0">{this.state.status.totalDoneOrder}</span>
													</div>
													<Col className="col-auto">
														<div className="icon icon-shape bg-warning text-white rounded-circle shadow">
															<i className="fas fa-chart-bar" />
														</div>
													</Col>
												</Row>
											)}
											<p className="mt-1 mb-0 text-muted text-sm">
												<span className="text-nowrap">{since}</span>
											</p>
										</CardBody>
									</Card>
								</Col>
								<Col lg="6" xl="3" className="mb-3">
									<Card className="card-stats mb-4 mb-xl-0">
										<CardBody>
											{this.state.status === null ? (
												<div className="text-center">
													<img src={LoadingPic} width="50" alt="..." />
												</div>
											) : (
												<Row>
													<div className="col">
														<CardTitle tag="h5" className="text-uppercase text-muted mb-0">
															Total Produk
														</CardTitle>
														<span className="h2 font-weight-bold mb-0">{this.state.status.totalProduct}</span>
													</div>
													<Col className="col-auto">
														<div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
															<i className="fas fa-plug" />
														</div>
													</Col>
												</Row>
											)}
											<p className="mt-1 mb-0 text-muted text-sm">
												<span className="text-nowrap">{since}</span>
											</p>
										</CardBody>
									</Card>
								</Col>
								<Col lg="6" xl="3" className="mb-3">
									<Card className="card-stats mb-4 mb-xl-0">
										<CardBody>
											{this.state.status === null ? (
												<div className="text-center">
													<img src={LoadingPic} width="50" alt="..." />
												</div>
											) : (
												<Row>
													<div className="col">
														<CardTitle tag="h5" className="text-uppercase text-muted mb-0">
															Total Customers
														</CardTitle>
														<span className="h2 font-weight-bold mb-0">{this.state.status.totalCustomer}</span>
													</div>
													<Col className="col-auto">
														<div className="icon icon-shape bg-info text-white rounded-circle shadow">
															<i className="fas fa-users" />
														</div>
													</Col>
												</Row>
											)}
											<p className="mt-1 mb-0 text-muted text-sm">
												<span className="text-nowrap">{since}</span>
											</p>
										</CardBody>
									</Card>
								</Col>
							</Row>
						</div>
					</Container>
				</div>
			</>
		);
	}
}

export default Header;
