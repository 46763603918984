import React from "react";
import { Button, Card, CardHeader, CardBody, Container, Row, Col, FormGroup, Form, Input, InputGroup, InputGroupAddon, InputGroupText, CustomInput } from "reactstrap";
import ProductHeaderProduct from "components/Headers/ProductHeaderProduct.js";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import ImageUploader from "react-images-upload";
import imageCompression from "browser-image-compression";
import SweetAlert from "react-bootstrap-sweetalert";
import axiosInstance from "../services/AxiosService";
import LoadingPic from "../assets/img/theme/ripple-loading.gif";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

const baseImgURL = process.env.REACT_APP_BACKEND_IMAGES;
class ManageProduct extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			product: null,
			isDiscount: false,
			isCutOff: false,
			alert: null,
			isMinOrder: false,
			taskDone: false,
			tempImage: null,
			tempPdf: null,
			loading: false,
		};
	}

	componentDidMount() {
		if (this.props.status === "Edit") {
			const url = `/cable/${this.props.match.params.idProduct}`;
			axiosInstance
				.get(url)
				.then((response) => {
					if (response.data.discount !== null) {
						this.setState({ isDiscount: true });
					}
					if (response.data.cutOff !== null) {
						this.setState({ isCutOff: true });
					}
					if (response.data.minPurchase !== null) {
						this.setState({ isMinOrder: true });
					}
					this.setState({ product: response.data });
				})
				.catch((error) => {
					alert(error.message);
				});
		} else {
			this.setState({
				...this.state,
				product: {
					id: null,
					name: "",
					conductor: "",
					insulation: "",
					category: "",
					desc: null,
					image: null,
					discount: "",
					cutOff: "",
					fireResist: false,
					isPopular: false,
					minPurchase: "",
					price: "",
					standart: "",
				},
				isCutOff: false,
				isDiscount: false,
				isMinOrder: false,
			});
		}
	}

	handleDeleteProduct(e) {
		e.preventDefault();
		const alertLogout = () => (
			<SweetAlert warning showCancel confirmBtnText="Ya, Hapus Produk" confirmBtnBsStyle="danger" cancelBtnText="Batal" title="Apakah Anda Yakin?" onConfirm={() => this.deleteProduct()} onCancel={() => this.setState({ alert: null })} focusCancelBtn>
				Produk yang dihapus tidak dapat dikembalikan!
			</SweetAlert>
		);

		this.setState({
			alert: alertLogout(),
		});
	}

	deleteProduct() {
		const url = `/cable/delete/${this.props.match.params.idProduct}`;
		axiosInstance
			.delete(url)
			.then((response) => {
				this.handleSuccessDelete("Hapus Product Berhasil");
			})
			.catch((error) => {
				alert(error.message);
			});
	}

	handleSuccessDelete(message) {
		const alertSuccess = () => (
			<SweetAlert success title="Berhasil!" onConfirm={() => this.closeTheAlert()}>
				{message}
			</SweetAlert>
		);
		this.setState({
			alert: alertSuccess(),
		});
	}

	closeTheAlert() {
		this.setState({
			alert: null,
			taskDone: true,
		});
	}

	countDiscount(price, percent) {
		if (percent === 0) {
			return price;
		} else {
			let potongan = (parseFloat(price) * parseFloat(percent)) / 100;
			return parseInt(price) - parseInt(potongan);
		}
	}

	handleSaveClick(e, idProduct) {
		e.preventDefault();
		if (this.props.status === "Add") {
			const alertSubmit = () => <SweetAlert showCancel confirmBtnText="Ya, Tambah Produk" confirmBtnBsStyle="primary" cancelBtnText="Batal" title="Tambahkan Produk?" onConfirm={() => this.saveProduct(null)} onCancel={() => this.setState({ alert: null })} focusCancelBtn></SweetAlert>;

			this.setState({
				alert: alertSubmit(),
			});
		} else {
			const alertSubmit = () => <SweetAlert showCancel confirmBtnText="Ya, Perbaharui Produk" confirmBtnBsStyle="primary" cancelBtnText="Batal" title="Perbarui Produk?" onConfirm={() => this.saveProduct(idProduct)} onCancel={() => this.setState({ alert: null })} focusCancelBtn></SweetAlert>;

			this.setState({
				alert: alertSubmit(),
			});
		}
	}

	async saveProduct(idProduct) {
		this.setState({ loading: true, alert: null });
		if (this.state.isDiscount === false) {
			this.setState({ product: { ...this.state.product, discount: "" } });
		}
		if (this.state.isMinOrder === false) {
			this.setState({ product: { ...this.state.product, minPurchase: "" } });
		}
		if (this.state.tempImage !== null) {
			const options = {
				maxSizeMB: 1,
				maxWidthOrHeight: 720,
				useWebWorker: true,
			};
			const compressedFile = await imageCompression(this.state.tempImage, options);
			var imgCompressed = new File([compressedFile], "uploaded_file.jpg", {
				type: "image/jpeg",
				lastModified: Date.now(),
			});
			let data = new FormData();
			data.append("image", imgCompressed);
			data.append("old_image", this.state.product.image);
			const url = `/files/upload`;
			const config = {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			};
			await axiosInstance
				.post(url, data, config)
				.then((respon) => {
					// console.log(respon.data.file);
					this.setState({
						product: { ...this.state.product, image: respon.data.file },
					});
				})
				.catch((error) => {
					alert(`Proses upload gagal`);
				});
		}
		if (this.state.tempPdf !== null) {
			let dataDokumen = new FormData();
			dataDokumen.append("desc", this.state.tempPdf);
			dataDokumen.append("old_desc", this.state.product.desc);

			const config2 = {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			};
			await axiosInstance
				.post("/files/uploadDocument", dataDokumen, config2)
				.then((res) => {
					this.setState({
						product: { ...this.state.product, desc: res.data.file },
					});
				})
				.catch((error) => {
					alert(`Proses upload dokumen gagal`, error.massage);
				});
		}

		this.submitProduct(idProduct);
	}

	async submitProduct(idProduct) {
		const dataToPost = this.state.product;
		let url = "";
		let message = "";
		if (this.props.status === "Edit") {
			url = `/cable/edit/${idProduct}`;
			message = "Produk Berhasil Diperbaharui!";
			await axiosInstance
				.put(url, dataToPost)
				.then((response) => {
					if (response.data === "Success") {
						this.handleSuccess(message);
					}
				})
				.catch((error) => {
					alert(error.message);
				});
		} else {
			url = `/cable/create`;
			message = "Produk Berhasil Ditambahkan!";
			await axiosInstance
				.post(url, dataToPost)
				.then((response) => {
					if (response.data === "Success") {
						this.handleSuccess(message);
					}
				})
				.catch((error) => {
					alert(error.message);
				});
		}
	}
	handleSuccess(message) {
		const alertSuccess = () => (
			<SweetAlert
				success
				title="Berhasil!"
				onConfirm={() =>
					this.setState({
						alert: null,
						taskDone: true,
						loading: true,
					})
				}
			>
				{message}
			</SweetAlert>
		);
		this.setState({
			alert: alertSuccess(),
		});
	}

	onDrop(pictureFiles) {
		this.setState({
			tempImage: pictureFiles[0],
		});
	}
	onDropPDF(pdfFile) {
		this.setState({
			tempPdf: pdfFile,
		});
	}

	formatRupiah(angka, prefix) {
		var number_string = String(angka)
				.replace(/[^,\d]/g, "")
				.toString(),
			split = number_string.split(","),
			sisa = split[0].length % 3,
			rupiah = split[0].substr(0, sisa),
			ribuan = split[0].substr(sisa).match(/\d{3}/gi);

		if (ribuan) {
			let separator = sisa ? "." : "";
			rupiah += separator + ribuan.join(".");
		}

		rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
		return prefix === undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
	}

	handleCheckListDiscount() {
		if (this.state.isDiscount === true) {
			this.setState({
				isDiscount: !this.state.isDiscount,
				product: {
					...this.state.product,
					discount: "",
				},
			});
		} else {
			this.setState({
				isDiscount: !this.state.isDiscount,
			});
		}
	}

	handleCheckListCutOff() {
		if (this.state.isCutOff === true) {
			this.setState({
				isCutOff: !this.state.isCutOff,
				isDiscount: false,
				product: {
					...this.state.product,
					discount: "",
					cutOff: "",
				},
			});
		} else {
			this.setState({
				isCutOff: !this.state.isCutOff,
				isDiscount: false,
				product: {
					...this.state.product,
					discount: "",
				},
			});
		}
	}

	countCutOff(price, cutOff) {
		if (cutOff === 0) {
			return price;
		} else {
			let potongan = parseFloat(price) - parseFloat(cutOff);
			if (potongan < 0) {
				return 0;
			}
			return parseInt(potongan);
		}
	}

	render() {
		if (this.state.taskDone === true) {
			return <Redirect to="/list-cable" />;
		}

		const product = this.state.product;
		return (
			<>
				<Helmet>
					<title>Produk Kabel PT Sentratek Metalindo</title>
					<meta name="description" content="Admin PT Sentratek Metalindo " />
				</Helmet>
				<ProductHeaderProduct statusPage={this.props.status} />
				<Container className="mt--9" fluid>
					{this.state.loading === true ? (
						<div
							className="text-center"
							style={{
								height: "100vh",
								zIndex: "100",
								position: "fixed",
								top: "50%",
								left: "50%",
							}}
						>
							<div className="rowalign-self-center">
								<img src={LoadingPic} alt="..." />
							</div>
						</div>
					) : null}
					<Row>
						<Col className="order-xl-1" xl="12">
							<Card className="bg-secondary shadow">
								<CardHeader className="bg-white border-0">
									<Row className="align-items-center">
										<Col xs="8">{this.props.status === "Add" ? <h3 className="mb-0">Add Product</h3> : <h3 className="mb-0">Edit Product</h3>}</Col>
										<Col className="text-right" xs="4">
											{this.props.status === "Edit" ? (
												<Button color="danger" onClick={(e) => this.handleDeleteProduct(e)} size="sm">
													Hapus Produk Ini
												</Button>
											) : null}
										</Col>
									</Row>
								</CardHeader>
								<CardBody>
									{product === null ? (
										<div className="text-center">
											<img src={LoadingPic} alt="..." />
										</div>
									) : (
										<Form onSubmit={(e) => this.handleSaveClick(e, product.id)}>
											<h6 className="heading-small text-muted mb-4">Product information</h6>
											<div className="pl-lg-4">
												<Row>
													<Col lg="6">
														<FormGroup>
															<label className="form-control-label" htmlFor="input-productName">
																Foto Produk
															</label>
															<Row>
																{this.props.status === "Edit" ? (
																	<Col lg="6">
																		<div
																			style={{
																				maxHeight: "200px",
																				width: "100%",
																				backgroundColor: "white",
																			}}
																			className="text-center"
																		>
																			<div style={{ height: "100%" }} className="rounded img-center shadow-lg--hover">
																				{product.image === null ? (
																					<div>
																						<span>Tidak ada Gambar</span>
																					</div>
																				) : (
																					<Zoom isZoomed={true}>
																						<img className="rounded img-center img-fluid shadow-lg--hover" src={`${baseImgURL}/${product.image}`} style={{ maxHeight: "200px" }} alt="..." />
																					</Zoom>
																				)}
																			</div>
																		</div>
																	</Col>
																) : null}

																<Col>
																	<ImageUploader className="imgUploader d-inline" name="quesImg" withIcon={true} singleImage={true} withPreview={true} buttonText="Pilih Gambar" onChange={(pictureFiles) => this.onDrop(pictureFiles)} imgExtension={[".jpg", ".jpeg", ".png"]} maxFileSize={2242880} fileSizeError="Ukuran File Terlalu Besar" label="Ukuran Maks: 2mb dengan format file .jpeg/.jpg/.png" />
																</Col>
															</Row>
														</FormGroup>
													</Col>
													<Col lg="6">
														<FormGroup>
															<label className="form-control-label" htmlFor="input-productName">
																Deskripsi Produk
															</label>
															<Input className="form-control-alternative" type="file" accept="application/pdf" onChange={(e) => this.onDropPDF(e.target.files[0])} />
															{product.desc !== null ? (
																<a href={`${process.env.REACT_APP_BACKEND_DOCUMENTS}/${product.desc}`} target="_blank" rel="noreferrer">
																	Buka Dokumen PDF
																</a>
															) : null}
														</FormGroup>
													</Col>
												</Row>
												<Row>
													<Col lg="6">
														<FormGroup>
															<label className="form-control-label" htmlFor="input-productName">
																Nama Produk
															</label>
															<Input
																className="form-control-alternative"
																id="input-productName"
																placeholder="Product Name"
																type="text"
																value={product.name}
																onChange={(e) =>
																	this.setState({
																		product: {
																			...product,
																			name: e.target.value,
																		},
																	})
																}
																required
															/>
														</FormGroup>
													</Col>
													<Col lg="6">
														<Row>
															<Col lg="6">
																<FormGroup>
																	<label className="form-control-label" htmlFor="input-conductor">
																		Conductor
																	</label>
																	<Input
																		className="form-control-alternative"
																		id="input-conductor"
																		placeholder="Conductor"
																		type="text"
																		value={product.conductor}
																		onChange={(e) =>
																			this.setState({
																				product: {
																					...product,
																					conductor: e.target.value,
																				},
																			})
																		}
																	/>
																</FormGroup>
															</Col>
															<Col lg="6">
																<FormGroup>
																	<label className="form-control-label" htmlFor="input-Insulation">
																		Insulation
																	</label>
																	<Input
																		className="form-control-alternative"
																		id="input-Insulation"
																		placeholder="Insulation"
																		type="text"
																		value={product.insulation}
																		onChange={(e) =>
																			this.setState({
																				product: {
																					...product,
																					insulation: e.target.value,
																				},
																			})
																		}
																	/>
																</FormGroup>
															</Col>
														</Row>
													</Col>
												</Row>
												<Row>
													<Col lg="6">
														<FormGroup>
															<label className="form-control-label" htmlFor="input-conductor">
																Kategori
															</label>
															<div>
																<CustomInput
																	type="radio"
																	name="optionCategory"
																	id="option1"
																	label="Low"
																	value="low"
																	inline
																	checked={product.category === "low" ? true : false}
																	onChange={() =>
																		this.setState({
																			product: {
																				...product,
																				category: "low",
																			},
																		})
																	}
																/>
																<CustomInput
																	type="radio"
																	name="optionCategory"
																	id="option2"
																	label="Medium"
																	value="medium"
																	inline
																	checked={product.category === "medium" ? true : false}
																	onChange={() =>
																		this.setState({
																			product: {
																				...product,
																				category: "medium",
																			},
																		})
																	}
																/>
																<CustomInput
																	type="radio"
																	name="optionCategory"
																	id="option3"
																	label="Special"
																	value="special"
																	inline
																	checked={product.category === "special" ? true : false}
																	onChange={() =>
																		this.setState({
																			product: {
																				...product,
																				category: "special",
																			},
																		})
																	}
																/>
															</div>
														</FormGroup>
													</Col>
													<Col lg="6">
														<FormGroup>
															<label className="form-control-label" htmlFor="input-Standart">
																Standart
															</label>
															<Input
																className="form-control-alternative"
																id="input-Standart"
																placeholder="Standart"
																type="text"
																value={product.standart}
																onChange={(e) =>
																	this.setState({
																		product: {
																			...product,
																			standart: e.target.value,
																		},
																	})
																}
															/>
														</FormGroup>
													</Col>
												</Row>
												<Row>
													<Col lg="6">
														<Row>
															<Col lg="6">
																<FormGroup>
																	<label className="form-control-label" htmlFor="input-Standart">
																		Fire Resistent
																	</label>
																	<div className="custom-control custom-checkbox mb-3">
																		<input
																			className="custom-control-input"
																			id="checkFire"
																			type="checkbox"
																			checked={product.fireResist}
																			onChange={() =>
																				this.setState({
																					product: {
																						...product,
																						fireResist: !product.fireResist,
																					},
																				})
																			}
																		/>
																		<label className="custom-control-label" htmlFor="checkFire">
																			Fire Resistent
																		</label>
																	</div>
																</FormGroup>
															</Col>
															<Col lg="6">
																<Col>
																	<FormGroup>
																		<label className="form-control-label" htmlFor="input-Standart">
																			Kategori
																		</label>
																		<div className="custom-control custom-checkbox mb-3">
																			<input
																				className="custom-control-input"
																				id="checkPopuler"
																				type="checkbox"
																				checked={product.isPopular}
																				onChange={(e) =>
																					this.setState({
																						product: {
																							...product,
																							isPopular: !product.isPopular,
																						},
																					})
																				}
																			/>
																			<label className="custom-control-label" htmlFor="checkPopuler">
																				Jadikan Kategori Populer
																			</label>
																		</div>
																	</FormGroup>
																</Col>
															</Col>
														</Row>
													</Col>
													<Col lg="6">
														<FormGroup>
															<label className="form-control-label" htmlFor="input-Standart">
																Price
															</label>
															<Input
																className="form-control-alternative"
																id="input-Price"
																placeholder="Price"
																type="number"
																value={product.price === null ? "" : product.price}
																onChange={(e) =>
																	this.setState({
																		product: {
																			...product,
																			price: parseInt(e.target.value),
																		},
																	})
																}
															/>
														</FormGroup>
													</Col>
												</Row>
												<Row>
													<Col>
														<FormGroup className="mb-0">
															<label className="form-control-label" htmlFor="input-conductor">
																Minimal Pembelian
															</label>
															<Row>
																<Col md="6">
																	<div className="custom-control custom-checkbox mb-3">
																		<input
																			className="custom-control-input"
																			id="checkMinPurchase"
																			type="checkbox"
																			checked={this.state.isMinOrder}
																			onChange={() =>
																				this.setState({
																					isMinOrder: !this.state.isMinOrder,
																				})
																			}
																		/>
																		<label className="custom-control-label" htmlFor="checkMinPurchase">
																			Masukan Minimal Pembelian
																		</label>
																	</div>
																</Col>
																<Col lg="6">
																	<FormGroup>
																		<InputGroup className="input-group-alternative mb-4">
																			<Input
																				className="form-control-alternative"
																				id="inputMinPurchase"
																				placeholder="Minimal Pembelian"
																				type="number"
																				min="1"
																				onChange={(e) =>
																					this.setState({
																						product: {
																							...product,
																							minPurchase: parseInt(e.target.value),
																						},
																					})
																				}
																				value={product.minPurchase === null ? "" : product.minPurchase}
																				disabled={!this.state.isMinOrder}
																			/>
																			<InputGroupAddon addonType="append">
																				<InputGroupText>Meter</InputGroupText>
																			</InputGroupAddon>
																		</InputGroup>
																	</FormGroup>
																</Col>
															</Row>
														</FormGroup>
													</Col>
													<Col>
														<FormGroup className="mb-0">
															<label className="form-control-label" htmlFor="input-conductor">
																Discount
															</label>
															<Row>
																<Col md="4">
																	<div className="custom-control custom-checkbox mb-3">
																		<input className="custom-control-input" id="checkDiskon" type="checkbox" checked={this.state.isDiscount} onChange={() => this.handleCheckListDiscount()} />
																		<label className="custom-control-label" htmlFor="checkDiskon">
																			Tambah Diskon
																		</label>
																	</div>
																</Col>
																<Col lg="4">
																	<FormGroup>
																		<InputGroup className="input-group-alternative mb-4">
																			<Input
																				className="form-control-alternative"
																				id="inputDiscount"
																				placeholder="Discount"
																				type="number"
																				min="1"
																				step="0.1"
																				max="100"
																				onChange={(e) =>
																					this.setState({
																						product: {
																							...product,
																							discount: parseFloat(e.target.value),
																						},
																					})
																				}
																				value={product.discount === null ? "" : parseFloat(product.discount)}
																				disabled={!this.state.isDiscount}
																			/>
																			<InputGroupAddon addonType="append">
																				<InputGroupText>
																					<i className="fa fa-percent" aria-hidden="true"></i>
																				</InputGroupText>
																			</InputGroupAddon>
																		</InputGroup>
																	</FormGroup>
																</Col>
																<Col lg="4">
																	{this.state.isDiscount === true ? (
																		<span>
																			{" "}
																			<b>Harga : </b>
																			{this.formatRupiah(this.countDiscount(product.price, product.discount), "Rp. ")}
																		</span>
																	) : null}
																</Col>
															</Row>
														</FormGroup>
														<FormGroup className="mb-0">
															<Row>
																<Col md="4">
																	<div className="custom-control custom-checkbox mb-3">
																		<input className="custom-control-input" id="checkPotongan" type="checkbox" checked={this.state.isCutOff} onChange={() => this.handleCheckListCutOff()} />
																		<label className="custom-control-label" htmlFor="checkPotongan">
																			Tambah Potongan Harga
																		</label>
																	</div>
																</Col>
																<Col lg="4">
																	<FormGroup>
																		<InputGroup className="input-group-alternative mb-4">
																			<InputGroupAddon addonType="append">
																				<InputGroupText>RP.</InputGroupText>
																			</InputGroupAddon>
																			<Input
																				className="form-control-alternative"
																				id="inputDiscount"
																				placeholder="Potongan Harga"
																				type="number"
																				min="1"
																				step="1"
																				onChange={(e) =>
																					this.setState({
																						product: {
																							...product,
																							cutOff: parseFloat(e.target.value),
																						},
																					})
																				}
																				value={product.cutOff === null ? "" : parseFloat(product.cutOff)}
																				disabled={!this.state.isCutOff}
																			/>
																		</InputGroup>
																	</FormGroup>
																</Col>
																<Col lg="4">
																	{this.state.isCutOff === true ? (
																		<span>
																			{" "}
																			<b>Harga : </b>
																			{this.formatRupiah(this.countCutOff(product.price, product.cutOff), "Rp. ")}
																		</span>
																	) : null}
																</Col>
															</Row>
														</FormGroup>
													</Col>
												</Row>
											</div>

											<hr className="my-4" />

											<div className="text-center">
												<Button color="primary" type="submit" size="md">
													Simpan
												</Button>
											</div>
										</Form>
									)}
								</CardBody>
							</Card>
						</Col>
					</Row>
					{this.state.alert}
				</Container>
			</>
		);
	}
}

export default ManageProduct;
