import React from "react";
import { Switch } from "react-router-dom";
import { Container, Row } from "reactstrap";
import AuthFooter from "components/Footers/AuthFooter.js";
import Login from "../views/Login";
class Auth extends React.Component {
	render() {
		return (
			<>
				<div className="main-content ">
					<Container>
						<Row className="justify-content-center">
							<Switch>
								<Login />
							</Switch>
						</Row>
						<AuthFooter />
					</Container>
				</div>
			</>
		);
	}
}

export default Auth;
