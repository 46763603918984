import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { Provider } from "react-redux";
import store from "./redux/Store";
import ProtectedRoute from "./services/ProtectRoute";
ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter basename="/">
			<Switch>
				<Route
					exact
					path="/login"
					render={(props) => <AuthLayout {...props} />}
				/>
				<ProtectedRoute path="/" component={AdminLayout} />
				<Redirect from="/" to="/home" />
			</Switch>
		</BrowserRouter>
	</Provider>,
	document.getElementById("root")
);
