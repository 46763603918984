import "../../assets/css/dataTables.bootstrap4.min.css";
import React, { Component } from "react";
import { Button, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import axiosInstance from "../../services/AxiosService";
import moment from "moment";

const $ = require("jquery");
$.Datatable = require("datatables.net");

export default class Tbl extends Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			taskDone: false,
		};
	}

	changeDateFormat = (date) => {
		let dateNew = moment(date).local().format("DD/MM/YYYY HH:mm");
		return dateNew;
	};

	componentDidMount() {
		this.$el = $(this.el);
		this.$el.DataTable({
			paging: true,
			scrollY: 400,
			select: true,
			searching: true,
			responsive: true,
			ordering: true,
			columns: [{ title: "Product Name" }, { title: "Backup Time" }, { title: "Price (Rp.)" }, { title: "Potongan Harga" }, { title: "Terakhir Update" }, { title: "Action", width: "15%" }],
			order: [[4, "desc"]],
			columnDefs: [
				{ responsivePriority: 1, targets: 0 },
				{ responsivePriority: 6, targets: -1 },
			],
		});
	}

	handleDeleteProduct(e, idProduct) {
		e.preventDefault();
		const alertLogout = () => (
			<SweetAlert warning showCancel confirmBtnText="Ya, Hapus Produk" confirmBtnBsStyle="danger" cancelBtnText="Batal" title="Apakah Anda Yakin?" onConfirm={() => this.deleteProduct(idProduct)} onCancel={() => this.setState({ alert: null })} focusCancelBtn>
				Produk yang dihapus tidak dapat dikembalikan!
			</SweetAlert>
		);

		this.setState({
			alert: alertLogout(),
		});
	}

	deleteProduct(idProduct) {
		const url = `/ups/delete/${idProduct}`;
		axiosInstance
			.delete(url)
			.then((response) => {
				if (response.data === "Success") {
					this.handleSuccess("Produk Berhasil Dihapus");
				}
			})
			.catch((error) => {
				alert(error.message);
			});
	}

	handleSuccess(message) {
		const alertSuccess = () => (
			<SweetAlert success title="Berhasil!" onConfirm={() => this.closeTheAlert()}>
				{message}
			</SweetAlert>
		);
		this.setState({
			alert: alertSuccess(),
		});
	}

	closeTheAlert() {
		this.setState({
			alert: null,
		});
		this.props.handleStatusDeleteUps();
	}

	formatRupiah(angka, prefix) {
		var number_string = String(angka)
				.replace(/[^,\d]/g, "")
				.toString(),
			split = number_string.split(","),
			sisa = split[0].length % 3,
			rupiah = split[0].substr(0, sisa),
			ribuan = split[0].substr(sisa).match(/\d{3}/gi);

		if (ribuan) {
			let separator = sisa ? "." : "";
			rupiah += separator + ribuan.join(".");
		}

		rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
		return prefix === undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
	}

	render() {
		// console.log(this.props.listProduct);
		return (
			<div>
				<table className="display table table-bordered text-center nowrap" width="100%" ref={(el) => (this.el = el)}>
					<tbody>
						{Object.values(this.props.listProduct).map((item) => (
							<tr key={item.id}>
								<td>{item.name}</td>
								<td>{item.backupTime === null ? "-" : item.backupTime}</td>
								<td>{item.price === null ? "-" : this.formatRupiah(item.price, "Rp. ")}</td>
								<td>{item.discount === null ? (item.cutOff === null ? "-" : this.formatRupiah(item.cutOff, "Rp. ")) : item.discount + "%"}</td>

								<td>{this.changeDateFormat(item.updated_at)}</td>
								<td style={{ padding: "15px" }}>
									<Row>
										<Col>
											<Button className="w-100" size="sm" color="primary" tag={Link} to={`/ups/edit/${item.id}`}>
												Edit
											</Button>
										</Col>
										<div className="w-100 mt-1"></div>
										<Col>
											<button className="btn btn-sm btn-danger w-100" onClick={(e) => this.handleDeleteProduct(e, `${item.id}`)}>
												Delete
											</button>
										</Col>
									</Row>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				{this.state.alert}
			</div>
		);
	}
}
