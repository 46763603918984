import React from "react";
import { Helmet } from "react-helmet";
import { Card, CardHeader, Container, Row } from "reactstrap";
import Header from "components/Headers/Header.js";
import DataTable from "../components/DataTables/TableCustomers.js";
import CardBody from "reactstrap/lib/CardBody";
import axiosInstance from "../services/AxiosService";
import LoadingPic from "../assets/img/theme/ripple-loading.gif";

class Tables extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			listCustomer: null,
			taskDone: false,
		};
	}

	fetchData() {
		const url = "/customers";
		axiosInstance
			.get(url)
			.then((response) => {
				this.setState({ listCustomer: response.data });
			})
			.catch((error) => {
				alert(error.message);
			});
	}
	componentDidMount() {
		this.fetchData();
	}

	render() {
		return (
			<>
				<Helmet>
					<title>Customer PT Sentratek Metalindo</title>
					<meta name="description" content="Admin PT Sentratek Metalindo " />
				</Helmet>
				<Header />
				<Container className="mt--7" fluid>
					<Row>
						<div className="col">
							<Card className="shadow">
								<CardHeader className="border-0">
									<h3 className="mb-0">List Customer</h3>
								</CardHeader>

								<CardBody>
									{this.state.listCustomer === null ? (
										<div className="text-center">
											<img src={LoadingPic} alt="..." />
										</div>
									) : (
										<DataTable listCustomer={this.state.listCustomer} />
									)}
								</CardBody>
							</Card>
						</div>
					</Row>
				</Container>
			</>
		);
	}
}

export default Tables;
