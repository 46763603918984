import React from "react";
import { Button, Container, Row, Col } from "reactstrap";
import moment from "moment";
import axiosInstance from "../../services/AxiosService";
import pic1 from "../../assets/img/brand/sentra2.jpg";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

class UserHeader2 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loadingConfirm: false,
			alert: null,
		};
	}

	changeDateFormat = (date) => {
		const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
		let dateNew = moment(date).local().format("DD/MM/YYYY");
		var splitted = dateNew.split("/");
		// let time = moment(date).local().format("HH:mm");
		return `${splitted[0]} ${monthNames[splitted[1] - 1]} ${splitted[2]}`;
	};

	render() {
		return (
			<>
				<div
					className="header pb-5 pt-1 pt-lg-4 d-flex align-items-center"
					style={{
						minHeight: "400px",
						backgroundImage: "url(" + pic1 + ")",
						backgroundSize: "cover",
						backgroundPosition: "center top",
					}}
				>
					<span className="mask bg-gradient-default opacity-8" />

					<Container className="d-flex align-items-center" fluid>
						<Row>
							<Col lg="12" md="10">
								{/* <h1 className="display-4 text-white">Kode Order: </h1>
								<h1 className="display-4 text-white ">Tanggal: s</h1>
								<h1 className="display-4 text-white mt-0 mb-5">Status: </h1> */}
							</Col>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

export default UserHeader2;
