import React from "react";
import { Link } from "react-router-dom";
import { DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Navbar, Nav, Container, Media } from "reactstrap";
import userPic from "assets/img/brand/employee.svg";
import SweetAlert from "react-bootstrap-sweetalert";
import CookiesService from "../../services/CookiesService";

class AdminNavbar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
		};
	}

	handleClickLogout(e) {
		e.preventDefault();
		const alertLogout = () => <SweetAlert custom showCancel confirmBtnText="Ya" cancelBtnText="Tidak" confirmBtnBsStyle="primary" title="Apakah Anda Yakin Keluar?" onConfirm={() => this.handleLogout()} onCancel={() => this.setState({ alert: null })} focusCancelBtn></SweetAlert>;

		this.setState({
			alert: alertLogout(),
		});
	}

	handleLogout() {
		CookiesService.remove("access_token");
		this.props.history.replace("/login");
	}

	render() {
		return (
			<>
				<Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
					<Container fluid>
						<Link className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block" to="/">
							{this.props.brandText}
						</Link>
						<Nav className="align-items-center d-none d-md-flex" navbar>
							<UncontrolledDropdown nav>
								<DropdownToggle className="pr-0" nav>
									<Media className="align-items-center">
										<span className="avatar avatar-sm rounded-circle">
											<img alt="..." src={userPic} />
										</span>
										<Media className="ml-2 d-none d-lg-block">
											<span className="mb-0 text-sm font-weight-bold">Admin</span>
										</Media>
									</Media>
								</DropdownToggle>
								<DropdownMenu className="dropdown-menu-arrow" right>
									<DropdownItem className="noti-title" header tag="div">
										<h6 className="text-overflow m-0">Hai, Apa Kabar?</h6>
									</DropdownItem>
									{/* <DropdownItem to="/admin/user-profile" tag={Link}>
										<i className="ni ni-single-02" />
										<span>My profile</span>
									</DropdownItem>
									<DropdownItem to="/admin/user-profile" tag={Link}>
										<i className="ni ni-settings-gear-65" />
										<span>Settings</span>
									</DropdownItem>
									<DropdownItem to="/admin/user-profile" tag={Link}>
										<i className="ni ni-calendar-grid-58" />
										<span>Activity</span>
									</DropdownItem>
									<DropdownItem to="/admin/user-profile" tag={Link}>
										<i className="ni ni-support-16" />
										<span>Support</span>
									</DropdownItem> */}
									<DropdownItem divider />
									<DropdownItem onClick={(e) => this.handleClickLogout(e)}>
										<i className="ni ni-user-run" />
										<span>Logout</span>
									</DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown>
						</Nav>
						{this.state.alert}
					</Container>
				</Navbar>
			</>
		);
	}
}

export default AdminNavbar;
