import React from "react";
import { Button, Card, CardHeader, CardBody, Container, Row, Col, Table } from "reactstrap";
import LoadingPic from "../assets/img/theme/ripple-loading.gif";
import UserHeader2 from "components/Headers/UserHeader2.js";
import userPic from "../assets/img/brand/teamwork.svg";
import SweetAlert from "react-bootstrap-sweetalert";
import axiosInstance from "../services/AxiosService";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import moment from "moment";

class Profile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			company: "",
			phone: "",
			code_order: "",
			detailOrders: null,
			alert: null,
			listNewOrder: [],
		};
	}

	fetchData() {
		const url = `/ordering/new/${this.props.match.params.idOrder}`;
		axiosInstance
			.get(url)
			.then((response) => {
				this.setState({ listNewOrder: response.data });
				console.log(response.data[0]);
				this.setState({
					name: response.data[0].customer.name,
					company: response.data[0].customer.company,
					phone: response.data[0].customer.phone,
					code_order: response.data[0].no_order,
				});
			})
			.catch((error) => {
				alert(error.message);
			});
	}

	componentDidMount() {
		this.fetchData();
	}

	handleNoted(e, noted) {
		e.preventDefault();
		const alertNoted = () => (
			<SweetAlert title="Catatan Pesanan" onConfirm={() => this.setState({ alert: null })}>
				<span>{noted == null ? <i style={{ opacity: "0.5" }}>Tidak ada catatan</i> : <span style={{ color: "red" }}>&quot;{noted}&quot;</span>}</span>
			</SweetAlert>
		);

		this.setState({
			alert: alertNoted(),
		});
	}
	changeDateFormat = (date) => {
		const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
		let dateNew = moment(date).local().format("DD/MM/YYYY");
		var splitted = dateNew.split("/");
		// let time = moment(date).local().format("HH:mm");
		return `${splitted[0]} ${monthNames[splitted[1] - 1]} ${splitted[2]}`;
	};
	convertPhone(phone) {
		let number = phone.substring(1);
		return `62${number}`;
	}

	formatRupiah(angka, prefix) {
		var number_string = String(angka)
				.replace(/[^,\d]/g, "")
				.toString(),
			split = number_string.split(","),
			sisa = split[0].length % 3,
			rupiah = split[0].substr(0, sisa),
			ribuan = split[0].substr(sisa).match(/\d{3}/gi);

		if (ribuan) {
			let separator = sisa ? "." : "";
			rupiah += separator + ribuan.join(".");
		}

		rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
		return prefix === undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
	}

	countDiscount(price, percent) {
		if (percent === null) {
			return price;
		} else {
			let potongan = (parseInt(price) * parseInt(percent)) / 100;
			return parseInt(price) - parseInt(potongan);
		}
	}
	countCutOff(price, cutOff) {
		if (cutOff === 0) {
			return price;
		} else {
			let potongan = parseFloat(price) - parseFloat(cutOff);
			if (potongan < 0) {
				return 0;
			}
			return parseInt(potongan);
		}
	}
	render() {
		const newOrder = this.state.listNewOrder;
		return (
			// <>
			// 	<Helmet>
			// 		<title>Detail Pesanan</title>
			// 		<meta name="description" content="Admin PT Sentratek Metalindo " />
			// 	</Helmet>
			// 	{newOrder === null ? (
			// 		<div className="d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
			// 			<img src={LoadingPic} alt="..." />
			// 		</div>
			// 	) : (
			// 		<div>
			// 			{/* <UserHeader name={newOrder.customer.name} company={newOrder.customer.company} datePurc={newOrder.ordering.created_at} confirmStatus={newOrder.ordering.confirm} idOrder={newOrder.ordering.id} orderCode={newOrder.ordering.no_order} /> */}
			// 			<Container className="mt--7" fluid>
			// 				<Row>
			// 					<Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
			// 						<Card className="card-profile shadow">
			// 							<Row className="justify-content-center">
			// 								<Col className="order-lg-2" lg="3">
			// 									<div className="card-profile-image">
			// 										<a href="#pablo" onClick={(e) => e.preventDefault()}>
			// 											<img alt="..." className="rounded-circle" src={userPic} />
			// 										</a>
			// 									</div>
			// 								</Col>
			// 							</Row>
			// 							<CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
			// 								<div className="d-flex justify-content-between">
			// 									<Button className="mr-4" color="info" href="#pablo" onClick={(e) => e.preventDefault()} size="sm">
			// 										Customer
			// 									</Button>
			// 									<Button className="float-right" color="default" href="#pablo" onClick={(e) => e.preventDefault()} size="sm">
			// 										Pelanggan
			// 									</Button>
			// 								</div>
			// 							</CardHeader>
			// 							<CardBody className="pt-0 pt-md-4">
			// 								<div className="text-center mt-md-5">
			// 									<h3>newOrder.customer.name</h3>
			// 									<div className="h5 font-weight-300">
			// 										<i className="ni location_pin mr-2" />
			// 										newOrder.customer.email
			// 									</div>
			// 									<div>
			// 										<i className="ni education_hat mr-2" />
			// 										newOrder.customer.company
			// 									</div>
			// 									<hr className="my-4" />
			// 									<p>Phone/WhatsApp : newOrder.customer.phone</p>
			// 									{/* {newOrder.customer.whatsapp === 1 ? (
			// 										<a
			// 											href={`http://wa.me/${this.convertPhone(newOrder.customer.phone)}`} // ini belum ganti 62
			// 											target="_blank"
			// 											rel="noreferrer"
			// 										>
			// 											Hubungi Melalui WhatsApp
			// 										</a>
			// 									) : null} */}
			// 								</div>
			// 							</CardBody>
			// 						</Card>
			// 					</Col>
			// 					<Col className="order-xl-1" xl="8">
			// 						<Card className="bg-secondary shadow">
			// 							<CardHeader className="bg-white border-0">
			// 								<Row className="align-items-center">
			// 									<Col xs="8">
			// 										<h3 className="mb-0 text-uppercase">Order List newOrder.ordering.type</h3>
			// 									</Col>
			// 									<Col className="text-right" xs="4">
			// 										<Button color="primary" size="sm">
			// 											Produk Pesanan
			// 										</Button>
			// 									</Col>
			// 								</Row>
			// 							</CardHeader>
			// 							<CardBody>
			// 								<Table className="align-items-center table-flush" responsive>
			// 									<thead className="thead-light">
			// 										<tr>
			// 											<th scope="col">Kode</th>
			// 											<th scope="col">Name</th>
			// 											<th scope="col">Company</th>
			// 											<th scope="col">Date</th>
			// 											{/* <th scope="col">Product</th> */}
			// 											<th scope="col">Action</th>
			// 										</tr>
			// 									</thead>

			// 									<tbody>
			// 										{Object.values(this.state.listNewOrder).map((item) => (
			// 											<tr key={item.id}>
			// 												<td>{item.no_order}</td>
			// 												<td className="text-capitalize">{item.customer.name}</td>
			// 												<td>{item.customer.company}</td>
			// 												<td>{this.changeDateFormat(item.created_at)}</td>
			// 												{/* <td>
			// 												<Button
			// 													size="sm"
			// 													className={(() => {
			// 														switch (item.type) {
			// 															case "cable":
			// 																return "text-uppercase bg-yellow text-white";
			// 															case "ups":
			// 																return "text-uppercase bg-info text-white";
			// 															case "3m":
			// 																return "text-uppercase bg-green text-white";
			// 															default:
			// 																return "text-uppercase bg-purple text-white";
			// 														}
			// 													})()}
			// 												>
			// 													{item.type}
			// 												</Button>
			// 											</td> */}
			// 												<td>
			// 													<Button color="primary" size="sm" tag={Link} to={`/detail-order/new/${item.type.toLowerCase()}/${item.no_order}`}>
			// 														Proses
			// 													</Button>
			// 												</td>
			// 											</tr>
			// 										))}
			// 									</tbody>
			// 								</Table>
			// 								{this.state.alert}
			// 							</CardBody>
			// 						</Card>
			// 					</Col>
			// 				</Row>
			// 			</Container>{" "}
			// 		</div>
			// 	)}
			// </>
			<>
				<Helmet>
					<title>Detail Pesanan</title>
					<meta name="description" content="Admin PT Sentratek Metalindo " />
				</Helmet>
				<UserHeader2 />
				<Container className="mt--8" fluid>
					<Card className="shadow mx-5" style={{ minHeight: "500px" }}>
						<CardHeader className="border-0">
							<Row className="align-items-center">
								<div className="col">
									<h3 className="mb-0">Pesanan {this.state.code_order}</h3>
								</div>
								<div className="col text-right">
									<Button color="danger" onClick={(e) => this.checkPesanan(e)} size="sm">
										Pesanan Terbaru
									</Button>
								</div>
							</Row>
						</CardHeader>
						<CardBody>
							{this.state.listNewOrder === null ? (
								<div className="text-center">
									<img src={LoadingPic} alt="..." />
								</div>
							) : (
								<div>
									{/* <div>
										<h2>{this.state.code_order}</h2>
										<h2>{this.state.name}</h2>
										<h2>{this.state.company}</h2>
										<h2>{this.state.phone}</h2>
									</div> */}
									<Table className="align-items-center table-flush" responsive>
										<thead className="thead-light">
											<tr>
												{/* <th scope="col">Kode</th> */}
												<th scope="col">Name</th>
												<th scope="col">Company</th>
												<th scope="col">Date</th>
												<th scope="col">Produk</th>
												<th scope="col">Status</th>
												<th scope="col">Action</th>
											</tr>
										</thead>

										<tbody>
											{Object.values(this.state.listNewOrder).map((item) => (
												<tr key={item.id}>
													{/* <td>{item.no_order}</td> */}
													<td className="text-capitalize">{item.customer.name}</td>
													<td>{item.customer.company}</td>
													<td>{this.changeDateFormat(item.created_at)}</td>
													<td>
														<Button
															size="sm"
															className={(() => {
																switch (item.type) {
																	case "cable":
																		return "text-uppercase bg-yellow text-white";
																	case "ups":
																		return "text-uppercase bg-info text-white";
																	case "3m":
																		return "text-uppercase bg-green text-white";
																	default:
																		return "text-uppercase bg-purple text-white";
																}
															})()}
														>
															{item.type}
														</Button>
													</td>
													<td>
														<b>{item.confirm === 0 ? "Belum diproses" : item.confirm === 1 ? "Diproses" : "Selesai"}</b>
													</td>
													<td>
														<Button color="primary" size="sm" tag={Link} to={`/detail-order/${item.no_order}/${item.type}/${item.id}`}>
															Proses
														</Button>
													</td>
												</tr>
											))}
										</tbody>
									</Table>
								</div>
							)}
						</CardBody>
					</Card>
				</Container>
			</>
		);
	}
}

export default Profile;
