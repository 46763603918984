import React from "react";
import { Helmet } from "react-helmet";
import { Card, CardHeader, Container, Row, Button, Input, Modal, ModalHeader, ModalBody, Progress, CardBody, CardTitle, Col } from "reactstrap";
import Header from "components/Headers/Header.js";
import DataTable from "../components/DataTables/TableEmail";
import DataTableHistory from "../components/DataTables/TableHistoryEmail";
import axiosInstance from "../services/AxiosService";
import LoadingPic from "../assets/img/theme/ripple-loading.gif";
import SweetAlert from "react-bootstrap-sweetalert";

class Tables extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			listEmail: null,
			listHistoryEmail: null,
			newEmail: {
				email: "",
				name: "",
				company: "",
				sales: "",
				active: 1,
			},
			taskDone: false,
			showModal: false,
			statusSendEmail: false,
			done: 0,
			sendingProcess: true,

			processedCount: 0,
			processed: [],
			isProcessing: false,
			isFinished: false,

			summary: null,
		};
		this.startedSending = this.startedSending.bind(this);
	}

	setModalShow(e, status) {
		e.preventDefault();
		if (status === true) {
			this.setState({
				showModal: true,
				taskDone: false,
			});
		} else {
			this.setState({
				showModal: false,
				taskDone: false,
			});
		}
	}

	async handleSaveEmail(e) {
		e.preventDefault();
		const url = `/email/add`;
		const dataToPost = this.state.newEmail;
		await axiosInstance
			.post(url, dataToPost)
			.then((response) => {
				console.log(response.data);
				if (response.data === "Success") {
					this.handleSuccess("Email Berhasil Ditambahkan");
				} else {
					this.handleError("Email Telah Terdaftar");
				}
			})
			.catch((error) => {
				console.log(error);
				alert("error.message");
			});
	}

	handleError(message) {
		const alertSuccess = () => (
			<SweetAlert
				danger
				title="Gagal!"
				onConfirm={() =>
					this.setState({
						alert: false,
						showModal: false,
					})
				}
			>
				{message}
			</SweetAlert>
		);
		this.setState({
			alert: alertSuccess(),
		});
	}

	handleSuccess(message) {
		const alertSuccess = () => (
			<SweetAlert success title="Berhasil!" onConfirm={() => this.closeTheModal()}>
				{message}
			</SweetAlert>
		);
		this.setState({
			alert: alertSuccess(),
		});
	}

	async closeTheModal() {
		await this.setState({
			alert: false,
			showModal: false,
			taskDone: true,
		});
		await this.fetchListEmail();
	}

	async fetchListEmail() {
		await this.setState({
			listEmail: null,
		});
		const url = "/email/show";
		await axiosInstance
			.get(url)
			.then((response) => {
				this.setState({ listEmail: response.data });
			})
			.catch((error) => {
				alert(error.message);
			});
	}

	async fetchHistoryEmail() {
		await this.setState({
			listHistoryEmail: null,
		});
		const url = "/email/history";
		await axiosInstance
			.get(url)
			.then((response) => {
				this.setState({ listHistoryEmail: response.data });
			})
			.catch((error) => {
				alert(error.message);
			});
	}

	async fetchSummaryEmail() {
		await this.setState({
			summary: null,
		});
		const url = "/tracking/result";
		await axiosInstance
			.get(url)
			.then((response) => {
				this.setState({ summary: response.data });
			})
			.catch((error) => {
				alert(error.message);
			});
	}

	async startedSending(e) {
		e.preventDefault();
		this.setState({
			statusSendEmail: true,
			processedCount: 0,
			processed: [],
			isProcessing: false,
			isFinished: false,
		});
	}

	componentDidMount() {
		this.fetchListEmail();
		this.fetchHistoryEmail();
		this.fetchSummaryEmail();
	}

	startLongProcess() {
		this.setState(
			{
				isProcessing: true,
				processed: [],
				processedCount: 0,
				isFinished: false,
			},
			() => {
				let processing = Array.from({ length: this.state.listEmail.length }, (_, i) => i);
				console.log(processing);
				const context = {
					processing,
					processedCount: this.state.processedCount,
					processed: [],
					finalizeProcess: this.finalizeProcess.bind(this),
				};

				this.runRecursively(context.processedCount, context);
			}
		);
	}

	runRecursively(newCounter, context) {
		const { processing, processed } = context;

		this.setState({ processedCount: newCounter }, async () => {
			// console.log(this.state.listEmail[context.processedCount+1]);

			const dataToPost = {
				name: this.state.listEmail[context.processedCount].name,
				email: this.state.listEmail[context.processedCount].email,
			};
			// console.log(dataToPost);
			const url = `/email/send/${this.state.listEmail[context.processedCount].id}`;
			axiosInstance.post(url, dataToPost).catch((error) => {
				console.log(error.message);
				// alert(error.message);
			});

			// Grab a item from processing array.
			const item = processing.shift();
			// Run a task that takes 2 seconds.
			const processedItem = await this.longTask(item);

			processed.push(processedItem);
			context.processedCount++;

			if (processing.length > 0) {
				// If there are more items.
				// Update counter so that it could be rendered on the page.
				this.runRecursively(context.processedCount, context);
			} else {
				// Otherwise call the finalize method.
				this.finalizeProcess(context);
			}
		});
	}

	finalizeProcess(context) {
		this.setState({
			processed: context.processed,
			// isProcessing: false,
			// isFinish:true,
			processedCount: context.processedCount,
			isFinished: true,
		});
	}
	finishingProcess(e) {
		e.preventDefault();
		this.setState({
			statusSendEmail: false,
		});
	}
	async longTask(item) {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(item + 1);
			}, 3000);
		});
	}

	render() {
		// console.log(this.state);
		// if (this.state.taskDone === true) {

		// }
		return (
			<>
				<Helmet>
					<title>Broadcast Email</title>
					<meta name="description" content="Admin PT Sentratek Metalindo " />
				</Helmet>
				<Header />
				<Container className="mt--7" fluid>
					<Row>
						<div className="col-12 mb-5">
							<Card className="shadow-lg">
								<CardHeader className="border-0">
									<h3 className="mb-0  d-inline">List Email</h3>
									<Button color="primary" size="md" className="fa-pull-right" onClick={() => this.fetchSummaryEmail()}>
										Refresh Ringkasan
									</Button>
								</CardHeader>

								<CardBody>
									{this.state.summary === null ? (
										<div className="text-center">
											<img src={LoadingPic} alt="..." />
										</div>
									) : (
										<Row>
											<Col lg="6" xl="4" className="mb-3">
												<Card className="card-stats mb-4 mb-xl-0">
													<CardBody>
														<Row>
															<div className="col">
																<CardTitle tag="h5" className="text-uppercase text-muted mb-0">
																	Total Pengiriman
																</CardTitle>
																<span className="h2 font-weight-bold mb-0">{this.state.summary.totalSended}</span>
															</div>
														</Row>
													</CardBody>
												</Card>
											</Col>
											<Col lg="6" xl="4" className="mb-3">
												<Card className="card-stats mb-4 mb-xl-0">
													<CardBody>
														<Row>
															<div className="col">
																<CardTitle tag="h5" className="text-uppercase text-muted mb-0">
																	Email Terklik
																</CardTitle>
																<span className="h2 font-weight-bold mb-0">{this.state.summary.totalEmailClicked}</span>
															</div>
														</Row>
													</CardBody>
												</Card>
											</Col>
											<Col lg="6" xl="4" className="mb-3">
												<Card className="card-stats mb-4 mb-xl-0">
													<CardBody>
														<Row>
															<div className="col">
																<CardTitle tag="h5" className="text-uppercase text-muted mb-0">
																	Email Belum Terklik
																</CardTitle>
																<span className="h2 font-weight-bold mb-0">{this.state.summary.totalAllNotClicked}</span>
															</div>
														</Row>
													</CardBody>
												</Card>
											</Col>

											<Col lg="6" xl="4" className="mb-3">
												<Card className="card-stats mb-4 mb-xl-0">
													<CardBody>
														<Row>
															<div className="col">
																<CardTitle tag="h5" className="text-uppercase text-muted mb-0">
																	Total Klik
																</CardTitle>
																<span className="h2 font-weight-bold mb-0">{this.state.summary.totalAllClicked}</span>
															</div>
														</Row>
													</CardBody>
												</Card>
											</Col>
											<Col lg="6" xl="4" className="mb-3">
												<Card className="card-stats mb-4 mb-xl-0">
													<CardBody>
														<Row>
															<div className="col">
																<CardTitle tag="h5" className="text-uppercase text-muted mb-0">
																	Total Email
																</CardTitle>
																<span className="h2 font-weight-bold mb-0">{this.state.summary.totalEmails}</span>
															</div>
														</Row>
													</CardBody>
												</Card>
											</Col>
											<Col lg="6" xl="4" className="mb-3">
												<Card className="card-stats mb-4 mb-xl-0">
													<CardBody>
														<Row>
															<div className="col">
																<CardTitle tag="h5" className="text-uppercase text-muted mb-0">
																	Total Email Terkirim
																</CardTitle>
																<span className="h2 font-weight-bold mb-0">{this.state.summary.emailCountSended}</span>
															</div>
														</Row>
													</CardBody>
												</Card>
											</Col>
										</Row>
									)}
								</CardBody>
							</Card>
						</div>
					</Row>

					{/* Card Emails */}
					<Row>
						<div className="col-12 mb-5">
							<Card className="shadow-lg">
								<CardHeader className="border-0">
									<h3 className="mb-0  d-inline">List Email</h3>
									<Button color="primary" size="md" className="fa-pull-right" onClick={(e) => this.setModalShow(e, true)}>
										Tambah Email
									</Button>
								</CardHeader>

								<CardBody>
									{this.state.listEmail === null ? (
										<div className="text-center">
											<img src={LoadingPic} alt="..." />
										</div>
									) : (
										<DataTable emails={this.state.listEmail} fetchListEmail={this.fetchListEmail.bind(this)} fetchHistoryEmail={this.fetchHistoryEmail.bind(this)} fetchSummaryEmail={this.fetchSummaryEmail.bind(this)} />
									)}
								</CardBody>
							</Card>
						</div>
					</Row>
					{this.state.alert}

					{/* Modal */}
					<Modal isOpen={this.state.showModal} toggle={(e) => this.setModalShow(e, false)} centered={true}>
						<ModalHeader toggle={(e) => this.setModalShow(e, false)}>
							<h2>Tambah Email</h2>
						</ModalHeader>
						<ModalBody>
							<form onSubmit={(e) => this.handleSaveEmail(e)}>
								<Input
									className="form-control mb-3"
									id="input-productName"
									placeholder="Name"
									type="text"
									onChange={(e) =>
										this.setState({
											newEmail: {
												...this.state.newEmail,
												name: e.target.value,
											},
										})
									}
								/>
								<Input
									className="form-control mb-3"
									id="input-productName"
									placeholder="Email"
									type="email"
									onChange={(e) =>
										this.setState({
											newEmail: {
												...this.state.newEmail,
												email: e.target.value,
											},
										})
									}
								/>
								<Input
									className="form-control mb-3"
									id="input-productName"
									placeholder="Nama Sales (Opsional)"
									type="text"
									onChange={(e) =>
										this.setState({
											newEmail: {
												...this.state.newEmail,
												sales: e.target.value,
											},
										})
									}
								/>
								<Input
									className="form-control mb-3"
									id="input-productName"
									placeholder="Nama Perusahaan (Opsional)"
									type="text"
									onChange={(e) =>
										this.setState({
											newEmail: {
												...this.state.newEmail,
												company: e.target.value,
											},
										})
									}
								/>
								<div className="fa-pull-right">
									<Button color="danger" onClick={(e) => this.setModalShow(e, false)}>
										Batal
									</Button>{" "}
									<Button type="submit" color="primary">
										Simpan
									</Button>
								</div>
							</form>
						</ModalBody>
					</Modal>

					{/* Modal Send all email*/}
					<Modal isOpen={this.state.statusSendEmail} centered={true}>
						<ModalBody className="text-center">
							{this.state.isProcessing === true ? (
								<div>
									{this.state.isFinished && <h2>Proses Selesai</h2>}
									{!this.state.isFinished && <h2 className="text-center">Sedang Mengirim...</h2>}
									{!this.state.isFinished && <p className="text-center">Harap Tunggu</p>}
									<Progress animated color="warning" value={(this.state.processedCount / this.state.listEmail.length) * 100} />
									<p>{this.state.processedCount}</p>
								</div>
							) : (
								<h2>Klik Mulai untuk memulai kirim email</h2>
							)}

							{!this.state.isFinished ? (
								<div>
									{!this.state.isProcessing && (
										<div>
											<Button color="danger" size="lg" className="" onClick={(e) => this.finishingProcess(e)}>
												Keluar
											</Button>
											<Button color="success" size="lg" className="" onClick={this.startLongProcess.bind(this)}>
												Mulai Kirim
											</Button>
										</div>
									)}
								</div>
							) : (
								<div>
									<Button color="success" size="lg" className="" onClick={(e) => this.finishingProcess(e)}>
										Keluar
									</Button>
								</div>
							)}
						</ModalBody>
					</Modal>

					<div className="col-12 mb-5 shadow-lg--hover bg-teal rounded p-3">
						<h1 className="mb-0 d-inline bold mr-5">Kirim ke semua Email</h1>
						<Button color="danger" size="lg" className="" onClick={(e) => this.startedSending(e)}>
							Kirim Ke Semua Email
						</Button>
					</div>

					{/* Card History Emails */}
					<Row>
						<div className="col-12 mb-5">
							<Card className="shadow-lg">
								<CardHeader className="border-0">
									<h3 className="mb-0  d-inline">Riwayat Pengiriman Email</h3>
									<Button color="primary" size="md" className="fa-pull-right" onClick={() => this.fetchHistoryEmail()}>
										Refresh Data Riwayat
									</Button>
								</CardHeader>

								<CardBody>
									{this.state.listHistoryEmail === null ? (
										<div className="text-center">
											<img src={LoadingPic} alt="..." />
										</div>
									) : (
										<DataTableHistory history={this.state.listHistoryEmail} />
									)}
								</CardBody>
							</Card>
						</div>
					</Row>
				</Container>
			</>
		);
	}
}

export default Tables;
