import "../../assets/css/dataTables.bootstrap4.min.css";
import React, { Component } from "react";
import "datatables.net-responsive";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.print";
import moment from "moment";

import $ from "jquery";
import JSZip from "jszip";
window.JSZip = JSZip;

export default class Tbl extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		this.$el = $(this.el);
		this.$el.DataTable({
			dom: "lBfrtip",
			paging: true,
			scrollY: 400,
			select: true,
			searching: true,
			responsive: {
				details: {
					type: "column",
				},
			},
			columnDefs: [
				{
					className: "dtr-control",
					orderable: false,
					targets: 0,
				},
			],
			order: [1, "desc"],
			ordering: true,

			buttons: [
				{
					extend: "excel",
					text: "Save To Excel",
					fileName: "BroadcastEmail.xlsx",
					exportOptions: {
						modifier: {
							page: "current",
						},
					},
				},
				{
					extend: "pdf",
					text: "Save To pdf",
					fileName: "BroadcastEmail.pdf",
					exportOptions: {
						modifier: {
							page: "current",
						},
					},
				},
			],
			columns: [{ title: "" }, { title: "Tanggal" }, { title: "Nama" }, { title: "Email" }, { title: "Sales" }, { title: "Company" }, { title: "Status" }, { title: "Jumlah Klik" }],
		});
	}

	changeDateFormat = (date) => {
		let dateNew = moment(date).local().format("DD/MM/YYYY HH:mm");
		return dateNew;
	};

	showErrorMessage(e, message) {
		e.preventDefault();

		alert(message);
	}

	render() {
		// console.log(this.props.history);
		const history = this.props.history;
		return (
			<div>
				<table className="display table table-bordered text-center nowrap" width="100%" ref={(el) => (this.el = el)}>
					<tbody>
						{Object.values(history).map((item) => (
							<tr key={item.id}>
								<td></td>
								<td>{this.changeDateFormat(item.created_at)}</td>
								<td>{item.emails.name}</td>
								<td>{item.emails.email}</td>
								<td>{item.emails.sales}</td>
								<td>{item.emails.company}</td>
								<td>
									{item.status === 0 ? (
										<i>Belum Klik</i>
									) : item.status === 2 ? (
										<b onClick={(e) => this.showErrorMessage(e, item.errorMessage)} style={{ color: "red", cursor: "pointer" }}>
											Gagal
										</b>
									) : (
										<b>Sudah Klik</b>
									)}
								</td>
								<td>{item.clickCount}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	}
}
